import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Tooltip
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backGround from '../../assets/login.svg';
import LockIcon from '@mui/icons-material/Lock';
import CSS from 'csstype';
import { colors } from '../../utils/theme';
import { useMutation, useQueryClient } from 'react-query';
import { ResetPassQuery } from '../../queries/account';
import { useNavigate, useLocation } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import queryString from 'query-string';
import { isValidEmail } from '../../utils/validators';
import { handleError } from '../../utils/ui';
import PasswordRequirements from '../Profile/SecurityAndSettings/PasswordRequirements';
import './ResetPassword.scss';

const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};
const ResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [passIsValid, setPassIsValid] = useState(false);
  const { mutate, isLoading } = useMutation(ResetPassQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.passChanged')
        }
      });
      navigate(PublicRoutes.Signin);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ResetPasswordQuery');
    }
  });

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setPassword(value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setConfirmPass(value);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password === confirmPass) {
      const parsed = queryString.parse(location.search);
      if (
        parsed.token &&
        parsed.token.length > 0 &&
        parsed.email &&
        isValidEmail(parsed.email as string)
      ) {
        mutate({
          token: parsed.token as string,
          email: parsed.email as string,
          password,
          confirmPassword: confirmPass
        });
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={8} component={Paper} square>
        <Box className="reset-pass-container">
          <Box className="reset-pass-container__title">{t('account.resetPasswordTitle')}</Box>
          <Box component="form" onSubmit={handleSignin} sx={{ mt: 1, maxWidth: 548 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: '16px',
                    sm: '16px',
                    md: '32px'
                  },
                  height: 66
                }}
              >
                <InputLabel htmlFor="password" style={labelStyle}>
                  {t('account.password')}
                </InputLabel>
                <Tooltip title={t('account.passwordRequirements')} placement="top">
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    type={visiblePass ? 'text' : 'password'}
                    id="password"
                    color="info"
                    placeholder={t('account.passPlaceholder') as string}
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisiblePass(!visiblePass)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visiblePass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 50, height: 66, marginBottom: 50 }}>
                <InputLabel htmlFor="confirm-password" style={labelStyle}>
                  {t('account.confirmPassword')}
                </InputLabel>
                <Tooltip title={t('account.passwordRequirements')} placement="top">
                  <TextField
                    margin="normal"
                    fullWidth
                    name="confirm-password"
                    type={visibleConfirmPass ? 'text' : 'password'}
                    id="confirm-password"
                    color="info"
                    placeholder={t('account.confirmPassPlaceholder') as string}
                    value={confirmPass}
                    onChange={handleConfirmPasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visibleConfirmPass ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <PasswordRequirements
                  password={password}
                  onValidityChange={setPassIsValid}
                  isWhiteTheme
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              className="custom-button reset-pass-container__button"
              fullWidth
              variant="contained"
              disabled={isLoading || !passIsValid || password !== confirmPass}
            >
              {t('account.send')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        sx={{
          backgroundImage: `url(${backGround})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
    </Grid>
  );
};

export default ResetPassword;
