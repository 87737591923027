import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useRef, useEffect } from 'react';
import { Location, NavigateFunction, useLocation } from 'react-router-dom';
import { AuthRoutes, PublicRoutes, RouteNames } from '../enums/RouteEnums';
import { VerificationStatuses } from '../models/account';
import {
  Flag,
  Start,
  Support,
  SwapHoriz,
  Storefront,
  EmojiEvents,
  CardGiftcard,
  AccountCircle,
  SettingsOutlined,
  AccountBalanceWallet
} from '@mui/icons-material';
import {
  GradientSupportIcon,
  GradientStorefrontIcon,
  GradientCardGiftcardIcon,
  GradientCupIcon,
  GradientFlagIcon
} from '../components/Gradients/GradientIcons';
import badgeZero from '../assets/shop/badges/guest.svg';
import badgeOne from '../assets/shop/badges/master.svg';
import badgeTwo from '../assets/shop/badges/expert.svg';
import badgeThree from '../assets/shop/badges/pro.svg';
import badgeFour from '../assets/shop/badges/elite.svg';
import badgeFive from '../assets/shop/badges/champion.svg';
import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appcontext';
import { BannerModel, BannerTranslation, BannerLine } from '../models/banner';
import { getCorrectTranslation } from './banners';
import { i18n } from 'i18next';
import { InitialStateType } from '../AppContext';
import { NavItem } from '../models/navigation';
import { useTranslation } from 'react-i18next';
import useSetMyAccountMenuIndex from '../customHooks/useSetMyAccountMenuIndex';

export const hideLayout = () => {
  const location = useLocation();
  const locationElements = location.pathname.split('/').filter((e) => e);

  const result =
    locationElements.length > 0 &&
    (locationElements[0].toUpperCase() === RouteNames.Signin.toUpperCase() ||
      locationElements[0].toUpperCase() === RouteNames.Signup.toUpperCase() ||
      locationElements[0].toUpperCase() === RouteNames.ConfirmEmail.toUpperCase() ||
      locationElements[0].toUpperCase() === RouteNames.ResetPass.toUpperCase() ||
      locationElements[0].toUpperCase() === RouteNames.LandingPage.toUpperCase() ||
      locationElements[0].toUpperCase() === RouteNames.LandingPageNotFound.toUpperCase());

  return result;
};

export default function ScrollRestoration() {
  const { key } = useLocation();
  const positions = useRef(new Map());

  useEffect(() => {
    if (positions.current.has(key)) {
      const { x, y } = positions.current.get(key);
      window.scrollTo(x, y);
    } else {
      window.scrollTo(0, 0);
    }

    const handler = () => {
      positions.current.set(key, { x: window.scrollX, y: window.scrollY });
    };

    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [key]);

  return null;
}

interface decoded {
  id: string;
  exp: number;
  name: string;
  family_name: string;
  given_name: string;
  email: string;
  roles: string[];
  actort: VerificationStatuses;
  groupsid: string[];
  primarygroupsid: string[];
  certpublickey: string;
  hasDeposited: boolean;
  agreedEmailPromotions: boolean;
}

export const decodeToken = (token: string) => {
  const decoded: decoded = jwtDecode(token);
  const hasDeposited =
    decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'] === 'True';
  const res = {
    ...decoded,
    hasDeposited
  } as decoded;
  return res;
};

export const getNavSelectedPage = (location: Location): number => {
  const routes = GetNavRoutes();
  const result = routes.indexOf(location.pathname);
  const returned = result >= 0 ? result + 1 : 0;

  return returned;
};

export const GetNavRoutes = (): string[] => {
  const isClone = process.env.ENVIRONMENT === 'licence';

  const routes = [
    PublicRoutes.Games,
    PublicRoutes.Shop,
    PublicRoutes.Promotions,
    PublicRoutes.Tournaments,
    PublicRoutes.Challenges
  ];
  const cloneRoutes = [PublicRoutes.Games, PublicRoutes.Promotions];

  return isClone ? cloneRoutes : routes;
};

export const displayText = (text: string | null, type?: string | undefined) => {
  if (type && type === 'money') {
    return (
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px'
        }}
      >
        {Number(text).toFixed(2)}
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '14px'
      }}
    >
      {text}
    </Typography>
  );
};

export const navigationItems = [
  {
    icon: Support,
    iconSelected: GradientSupportIcon,
    text: 'navigation.games',
    onClick: (navigate: NavigateFunction, location: Location) => {
      if (location && location.pathname === PublicRoutes.Games) {
        navigate(0);
      }
      navigate(PublicRoutes.Games);
    },
    showOnClone: true
  },
  {
    icon: Storefront,
    iconSelected: GradientStorefrontIcon,
    text: 'navigation.shop',
    onClick: (navigate: NavigateFunction) => navigate(PublicRoutes.Shop),
    showOnClone: false
  },
  {
    icon: CardGiftcard,
    iconSelected: GradientCardGiftcardIcon,
    text: 'navigation.promotions',
    onClick: (navigate: NavigateFunction) => navigate(PublicRoutes.Promotions),
    showOnClone: true
  },
  {
    icon: EmojiEvents,
    iconSelected: GradientCupIcon,
    text: 'navigation.tournaments',
    onClick: (navigate: NavigateFunction) => navigate(PublicRoutes.Tournaments),
    showOnClone: false
  },
  {
    icon: Flag,
    iconSelected: GradientFlagIcon,
    text: 'navigation.challenges',
    onClick: (navigate: NavigateFunction) => navigate(PublicRoutes.Challenges),
    showOnClone: false
  }
] as NavItem[];

export const mobileNavigationItems = [
  {
    icon: Support,
    text: 'navigation.games',
    path: PublicRoutes.Games,
    showOnClone: true
  },
  {
    icon: Storefront,
    text: 'navigation.shop',
    path: PublicRoutes.Shop,
    showOnClone: false
  },
  {
    icon: CardGiftcard,
    text: 'navigation.promotions',
    path: PublicRoutes.Promotions,
    showOnClone: true
  },
  {
    icon: EmojiEvents,
    text: 'navigation.tournaments',
    path: PublicRoutes.Tournaments,
    showOnClone: false
  },
  {
    icon: Flag,
    text: 'navigation.challenges',
    path: PublicRoutes.Challenges,
    showOnClone: false
  }
] as NavItem[];

export const myAccountMobileNavItems = [
  {
    icon: AccountCircle,
    text: 'myAccount.accountPage.accountDetails',
    path: '0',
    showOnClone: true
  },
  {
    icon: AccountBalanceWallet,
    text: 'wallet.deposit',
    path: '1',
    showOnClone: true
  },
  {
    icon: CardGiftcard,
    text: 'bonus.overview',
    path: '2',
    showOnClone: true
  },
  {
    icon: Start,
    text: 'wallet.withdraw',
    path: '3',
    showOnClone: true,
    iconRotationDegrees: '270'
  },
  {
    icon: SwapHoriz,
    text: 'myAccount.accountPage.transactions',
    path: '4',
    showOnClone: true
  },
  {
    icon: SettingsOutlined,
    text: 'myAccount.accountPage.securityAndSettings',
    path: '5',
    showOnClone: true
  }
] as NavItem[];

export const getNavItems = (isMobile = false) => {
  const items = isMobile ? mobileNavigationItems : navigationItems;
  const isClone = process.env.ENVIRONMENT === 'licence';
  return isClone ? items.filter((i) => i.showOnClone) : items;
};

export const getBadge = (index: number) => {
  switch (index) {
    case 1:
      return badgeOne;
    case 2:
      return badgeTwo;
    case 3:
      return badgeThree;
    case 4:
      return badgeFour;
    case 5:
      return badgeFive;
    default:
      return badgeZero;
  }
};

export const slideRight = (ref: React.MutableRefObject<null>, shift: number) => {
  if (ref.current) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    ref.current.scrollLeft += shift;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (response: any, dispatch: React.Dispatch<ActionType>) => {
  switch (response.status) {
    case 401:
      dispatch({ type: Actions.RemoveUser });
      break;
    default:
      const message = response.status === 400 ? response.data.error : 'An error occurred';
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          autoHide: 6000,
          severity: 'error',
          text: message
        }
      });
      break;
  }
};

export const getUserCurrencySymbol = (currency: string | undefined) => {
  const input = currency?.toUpperCase() ?? 'EUR';
  const data = {
    EUR: '\u20AC',
    USD: '\u0024',
    GBP: '\u00A3',
    RUB: '\u20BD'
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data[input];
};

export const getBannerText = (
  banner: BannerModel,
  i18n: i18n,
  state: InitialStateType,
  navigate: NavigateFunction,
  dispatch: React.Dispatch<ActionType>,
  isShop?: boolean
) => {
  const { t } = useTranslation();
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);
  const handleBannerButon = () => {
    if (banner.link === PublicRoutes.Wheel) {
      if (state.user.data && state.user.data.hasDeposited) {
        dispatch({ type: Actions.ShowWheelPopup });
      } else {
        if (state.user.data === null) {
          dispatch({
            type: Actions.ShowLoginPopup,
            payload: {}
          });
        } else {
          setMyAccountDepositIndex();
          dispatch({
            type: Actions.ShowPopup,
            payload: {
              state: t('bannersContainer.popUpMessage'),
              isCongrat: false,
              redirectUrl: AuthRoutes.MyAccount,
              buttonText: t('bannersContainer.buttonText') as string
            }
          });
        }
      }
    } else {
      navigate(
        banner.link === PublicRoutes.Signup && state.user.data ? AuthRoutes.Wallet : banner.link
      );
    }
  };

  const getBannerTranslations = () => {
    const bannerTranslations: BannerTranslation = {
      language: '',
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      buttonText: '',
      text: null
    };

    banner.translations.forEach((translation) => {
      const translationKeys = Object.keys(translation).map((key) => key);

      translationKeys.forEach((line) => {
        const lineKey = line as keyof BannerTranslation;
        const correctTranslation = getCorrectTranslation(banner, i18n, state)?.[lineKey] || '';

        bannerTranslations[lineKey] = correctTranslation;
      });
    });

    return bannerTranslations;
  };

  const getBannerLinesData = () => {
    const lines: BannerLine[] = [];
    const bannerTranslations = getBannerTranslations();

    Object.keys(bannerTranslations).forEach((translation) => {
      if (~translation.indexOf('line')) {
        const translationKey = translation as keyof BannerTranslation;
        const bannerLine = banner.lines.find((line) => line.key === translation);
        const lineText = bannerTranslations[translationKey];

        if (bannerLine) {
          lines.push({ ...bannerLine, lineText });
        }
      }
    });

    return lines;
  };

  const renderBannerLines = () => {
    const bannerLinesData = getBannerLinesData();
    const sortedBannerLinesDataByKey = bannerLinesData.sort((a, b) => a.key.localeCompare(b.key));
    const bannerLines = sortedBannerLinesDataByKey.map((bannerLineData) => (
      <Box
        key={bannerLineData.key}
        sx={{
          color: bannerLineData.fontColor,
          fontFamily: bannerLineData.font,
          fontWeight: bannerLineData.fontWeight
        }}
        className={`banner-text__line banner-text__line--font-size-${bannerLineData.fontSize.toLowerCase()}`}
      >
        <Box
          className={banner.glitchText ? 'glitch' : ''}
          data-glitch={bannerLineData.lineText || ''}
          data-glitch-color={bannerLineData.fontColor}
        >
          {bannerLineData.lineText || ''}
        </Box>
      </Box>
    ));

    return bannerLines;
  };

  return (
    <Box
      className={`banner-text ${
        isShop ? 'banner-text--is-shop' : ''
      } banner-text--align-${banner.contentAlignment.toLowerCase()}`}
    >
      {renderBannerLines()}
      <Box id="button-container" className="banner-text__button-container">
        <Button onClick={handleBannerButon} className="banner-text__button">
          {getCorrectTranslation(banner, i18n, state)?.buttonText}
        </Button>
      </Box>
    </Box>
  );
};
