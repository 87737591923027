import React, { useState } from 'react';
import { BannerModel } from '../../../models/banner';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AnimatedHomePageBanner from './AnimatedHomePageBanner';
import HomePageBanner from './HomePageBanner';

interface IProps {
  banners: BannerModel[];
  isShop?: boolean;
}

const BannerContainerNew: React.FunctionComponent<IProps> = ({ banners }: IProps) => {
  const [boxHeight, setBoxHeight] = useState(465);

  const renderBanners = () => {
    const bannersArray: JSX.Element[] = [];

    banners.forEach((banner, i) => {
      if (
        banner.jsonUrl &&
        banner.jsonUrl.length > 0 &&
        banner.atlasUrl &&
        banner.atlasUrl.length > 0
      ) {
        bannersArray.push(
          <AnimatedHomePageBanner
            index={i}
            banner={banner}
            key={`banner-${i}`}
            setContainer={setBoxHeight}
          />
        );
      } else {
        bannersArray.push(
          <HomePageBanner key={`banner-static-${i}`} banner={banner} setContainer={setBoxHeight} />
        );
      }
    });

    return bannersArray;
  };

  return (
    <Carousel
      className="banner_container"
      NextIcon={<ArrowForwardIosIcon style={{ color: 'white' }} />}
      PrevIcon={<ArrowBackIosNewIcon style={{ color: 'white' }} />}
      indicators={false}
      duration={800}
      height={boxHeight}
      interval={5700}
    >
      {banners.length > 0 && renderBanners()}
    </Carousel>
  );
};

export default BannerContainerNew;
