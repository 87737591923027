import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);
const getHeaders = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const PlayerActiveBonus = async () => {
  return await axios.get(`${baseUrl}/bonus/GetPlayerActiveBonus`, getHeaders());
};

export const GetPlayerActiveCashbackBonus = async () => {
  return await axios.get(`${baseUrl}/bonus/GetPlayerActiveCashbackBonus`, getHeaders());
};

export const PlayerSuggestedBonuses = async () => {
  return await axios.get(`${baseUrl}/bonus/GetPlayerSuggestedBonuses`, getHeaders());
};

export const PlayerPastBonuses = async () => {
  return await axios.get(`${baseUrl}/bonus/GetPlayerPastBonuses`, getHeaders());
};

export const ClaimBonus = async (code: string) => {
  return await axios.post(`${baseUrl}/bonus/ClaimBonus`, { code }, getHeaders());
};

export const DeclineBonus = async (code: string) => {
  return await axios.post(`${baseUrl}/bonus/DeclineBonus`, { code }, getHeaders());
};

export const BonusPromotions = async () => {
  return await axios.get(`${baseUrl}/bonus/Promotions`, getToken() ? getHeaders() : undefined);
};

export const PlayerFreeSpins = async () => {
  return await axios.get(`${baseUrl}/bonus/PlayerFreeSpins`, getToken() ? getHeaders() : undefined);
};

export const HomeScreenPromotions = async () => {
  return await axios.get(`${baseUrl}/bonus/HomePromotions`, getToken() ? getHeaders() : undefined);
};

export const GetBonusDetails = async (code: string) => {
  return await axios.get(
    `${baseUrl}/bonus/GetDetails/${code}`,
    getToken() ? getHeaders() : undefined
  );
};

export const PacksQuery = async () => {
  return await axios.get(`${baseUrl}/bonus/GetAllPacksClient`, getHeaders());
};

export const ClaimCashback = async (id: number) => {
  return await axios.post(`${baseUrl}/cashbackbonus/claim`, { id }, getHeaders());
};
