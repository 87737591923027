import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { useQuery } from 'react-query';
//* MUI
import { Box, Grow } from '@mui/material';
//* Components
import FreeSpinsPrizeList from './FreeSpinsPrizeList';
import FreeSpinsGamesList from './FreeSpinsGamesList';
//* Models
import { IFreeSpinExchange, IFreeSpinOfferViewModel } from '../../../models/shop';
//* Enums
import { Query } from '../../../enums/RequestEnums';
//* Utils
import { handleError } from '../../../utils/ui';
//* Queries
import { GetFreeSpinGames } from '../../../queries/shop';
import { Actions } from '../../../enums/ActionEnums';
import { isUserLoggedIn } from '../../../utils/validators';
import { PublicRoutes } from '../../../enums/RouteEnums';

interface IProps {
  playerLevel: number;
}

const FreeSpinsShop: React.FunctionComponent<IProps> = ({ playerLevel }) => {
  const { dispatch } = useContext(AppContext);
  const [freeSpinsSelected, setFreeSpinsSelected] = useState<IFreeSpinExchange | null>(null);
  const [selectedFreeSpin, setSelectedFreeSpin] = useState<IFreeSpinOfferViewModel | null>(null);
  const containerRef = useRef(null);

  const freeSpinQuery = useQuery(Query.FreeSpins, GetFreeSpinGames, {
    onSuccess: ({ data }) => {
      setFreeSpinOffers(data);
    },
    onError: ({ response }) => handleError(response, dispatch),
    staleTime: 30 * 60 * 1000
  });

  const [freeSpinOffers, setFreeSpinOffers] = useState<IFreeSpinOfferViewModel[]>(
    freeSpinQuery.data?.data ?? []
  );

  const handleSelect = (value: IFreeSpinOfferViewModel | null) => {
    if (!isUserLoggedIn()) {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          state: { path: PublicRoutes.Shop }
        }
      });
    } else {
      setSelectedFreeSpin(value);
    }
  };

  return (
    <Grow in timeout={1000}>
      <Box className="free-spins shop-page__row">
        <Box className="shop-page__row-item--wide">
          <FreeSpinsGamesList
            freeSpinOffers={freeSpinOffers}
            selectedFreeSpin={selectedFreeSpin}
            handleSelect={handleSelect}
          />
        </Box>
        <Box ref={containerRef} className="shop-page__row-item--narrow">
          <FreeSpinsPrizeList
            selectedFreeSpin={selectedFreeSpin}
            resetSelectedFreeSpin={() => setSelectedFreeSpin(null)}
            selectedFreeSpinItem={freeSpinsSelected}
            onChange={setFreeSpinsSelected}
            playerLevel={playerLevel}
          />
        </Box>
      </Box>
    </Grow>
  );
};

export default FreeSpinsShop;
