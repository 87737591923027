import { useTranslation } from 'react-i18next';
import { AppConsts } from '../enums/AppConsts';

const validEmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (input: string): boolean => {
  const isValidEmail = input.match(validEmailRegex);
  return isValidEmail !== null;
};

export const isPasswordValid = (input: string): boolean => {
  let result = true;
  if (input.length < 6) {
    result = false;
  }
  if (input.search(/[a-z]/i) < 0) {
    result = false;
  }
  if (input.search(/[0-9]/) < 0) {
    result = false;
  }
  return result;
};

export const isUserLoggedIn = () => localStorage.getItem(AppConsts.AccessToken) !== null;

export const changePassRequirements = (password: string, t: any) => {
  return [
    {
      test: /.{8,}/,
      label: t('myAccount.accountPage.passwordPage.eightCharachtersRequirement'),
      isValid: password.length >= 8
    },
    {
      test: /\d/,
      label: t('myAccount.accountPage.passwordPage.numberRequirement'),
      isValid: /\d/.test(password)
    },
    {
      test: /[A-Z]/,
      label: t('myAccount.accountPage.passwordPage.capitalLetterRequirement'),
      isValid: /[A-Z]/.test(password)
    },
    {
      test: /[!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|-]/,
      label: t('myAccount.accountPage.passwordPage.specialCharachterRequirement'),
      isValid: /[!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|-]/.test(password)
    }
  ];
};

export const calculateExchangeMax = (loyaltyPoints: number | undefined, rate: number) => {
  const checkValue = loyaltyPoints ?? 100;
  return Math.floor(checkValue / rate);
};

export const parseToNumberOrZero = (input: string | (string | null)[] | null) => {
  // If the input is null, return 0
  if (input === null) {
    return 0;
  }

  // If the input is a string, try to parse it into a number
  if (typeof input === 'string') {
    const parsedNumber = Number(input);
    return isNaN(parsedNumber) ? 0 : parsedNumber;
  }

  // If the input is an array, try to parse the first non-null element into a number
  if (Array.isArray(input)) {
    for (const element of input) {
      if (typeof element === 'string') {
        const parsedNumber = Number(element);
        if (!isNaN(parsedNumber)) {
          return parsedNumber;
        }
      }
    }
  }

  // If none of the above conditions match, return 0
  return 0;
};
