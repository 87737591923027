import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Tabs,
  Tab,
  Button
} from '@mui/material';
import {
  ArrowDropUp,
  ArrowDropDown,
  PersonAddAlt1,
  Login,
  AccountCircle,
  AccountBalanceWallet
} from '@mui/icons-material';
import CSS from 'csstype';
import { PublicRoutes } from '../../enums/RouteEnums';
import logo from '../../assets/logo.svg';
import logoClone from '../../assets/logo_clone.svg';
import { AppContext } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/theme';
import {
  getNavSelectedPage,
  getUserCurrencySymbol,
  handleError,
  getNavItems
} from '../../utils/ui';
import { Actions } from '../../enums/ActionEnums';
import { hideLayout } from '../../utils/ui';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ChallengeGroupCompleted, ChallengeBadge } from '../../models/challenges';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { AllGamesQuery } from '../../queries/games';
import { IGameDto } from '../../models/games';
import { Query } from '../../enums/RequestEnums';
import { PulseLoader } from 'react-spinners';
import { BonusMaxWin } from '../../models/bonus';
import queryString from 'query-string';
import { UnsubscribeQuery } from '../../queries/account';
import MobileNavigation from './Mobile/MobileNavigation';
import { LanguagesQuery } from '../../queries/languages';
import { ILanguageBaseModel } from '../../models/language';
import ProfileMenu from '../Profile/ProfileMenu/ProfileMenu';
import WalletMenu from '../Profile/WalletMenu/WalletMenu';
import './Navigation.scss';
import { useCookies } from 'react-cookie';
import { UpdateAccountLanguageQuery } from '../../queries/profile';

const tabStyle: CSS.Properties = {
  paddingLeft: '6px',
  paddingRight: '6px',
  marginRight: '12px',
  paddingTop: 0,
  fontSize: '14px',
  lineHeight: '22px'
};

const tabIconStyle: CSS.Properties = {
  width: '20px',
  height: '20px',
  marginRight: '10px !important'
};

const arrowStyle: CSS.Properties = {
  color: 'common.white',
  marginLeft: '2px',
  marginRight: '-7px'
};

export let isGameLoaded = false;

const Navigation: React.FunctionComponent = () => {
  const [cookies, setCookie] = useCookies(['language']);
  const isClone = process.env.ENVIRONMENT === 'licence';
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const hideNav = hideLayout();
  const location = useLocation();
  const [hideWallet, setHideWallet] = useState(false);
  const { t, i18n } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const isSignedIn = !!state.user.token;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null);
  const [anchorElWallet, setAnchorElWallet] = useState<null | HTMLElement>(null);
  const [selecteDbLanguage, setSelectedDbLanguage] = useState<ILanguageBaseModel | null>(null);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [walletConnection, setWalletConnection] = useState<HubConnection | null>(null);
  const [challengeConnection, setChallengeConnection] = useState<HubConnection | null>(null);
  const parsed = queryString.parse(location.search);
  const { affid, cxd, unsubs } = parsed;

  useQuery(Query.AllGames, AllGamesQuery, {
    onSuccess: ({ data }) => {
      isGameLoaded = true;
      const currentGames = data as IGameDto[];
      dispatch({
        type: Actions.SetGames,
        payload: currentGames
      });
    },
    onError: ({ response }) => {
      isGameLoaded = true;
      handleError(response, dispatch);
    },
    onSettled: () => {
      queryClient.invalidateQueries('AllGamesQuery');
    }
  });

  const languagesGetQuery = useQuery({
    queryKey: Query.Languages,
    queryFn: LanguagesQuery,
    onSuccess: ({ data }) => {
      setLanguages(data);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [languages, setLanguages] = useState<ILanguageBaseModel[] | []>(
    languagesGetQuery.data?.data || []
  );

  const unsubscribe = useMutation(UnsubscribeQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.unsubscribe')
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const updateUserLanguage = useMutation(UpdateAccountLanguageQuery, {
    onError: ({ response }) => handleError(response, dispatch)
  });

  useEffect(() => {
    if (languages.length > 0) {
      const resources: any = {};

      languages.forEach((lang) => {
        const parsedTemplate = JSON.parse(lang.template);
        const flattenedTranslations = parsedTemplate.reduce((acc: any, obj: any) => {
          Object.keys(obj).forEach((key) => {
            acc[key] = obj[key];
          });
          return acc;
        }, {});
        resources[lang.code.toLowerCase()] = { translation: flattenedTranslations };
      });
      i18n.services.resourceStore.data = {
        ...i18n.services.resourceStore.data,
        ...resources
      };
      const storedLanguage = cookies.language;
      const defaultLanguage = languages.find((l) => l.code.toLowerCase() === 'en');
      if (storedLanguage?.length > 0) {
        const language = languages.find((l) => l.code.toLowerCase() === storedLanguage);
        if (language) {
          setSelectedDbLanguage(language);
          i18n.changeLanguage(language.code.toLowerCase());
          if (isSignedIn) {
            updateUserLanguage.mutate({
              language: language.code as string
            });
          }
        } else {
          setSelectedDbLanguage(defaultLanguage || null);
          i18n.changeLanguage(defaultLanguage?.code.toLowerCase());
        }
      } else {
        setSelectedDbLanguage(defaultLanguage || null);
        i18n.changeLanguage(defaultLanguage?.code.toLowerCase());
      }
    }
  }, [languages, cookies?.language, state.user?.token]);

  useEffect(() => {
    setPage(getNavSelectedPage(location));
    const locationElements = location.pathname.split('/').filter((e) => e);
    setHideWallet(
      locationElements.length === 2 && `/${locationElements[0]}` === PublicRoutes.Games
    );
  }, [location]);

  useEffect(() => {
    if (isSignedIn) {
      const newWalletConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.BASE_SEAMLESS_ADDRESS}/hubs/wallet`, {
          accessTokenFactory: () => state.user.token as string
        })
        .withAutomaticReconnect()
        .build();
      setWalletConnection(newWalletConnection);
      const newChallengeConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.BASE_SERVER_HUB_ADDRESS}/challenges`, {
          accessTokenFactory: () => state.user.token as string
        })
        .withAutomaticReconnect()
        .build();
      setChallengeConnection(newChallengeConnection);
    } else {
      if (walletConnection) {
        walletConnection.stop();
        setWalletConnection(null);
      }
      if (challengeConnection) {
        challengeConnection.stop();
        setChallengeConnection(null);
      }
    }
    return () => {
      if (walletConnection) {
        walletConnection.stop();
        setWalletConnection(null);
      }
      if (challengeConnection) {
        challengeConnection.stop();
        setChallengeConnection(null);
      }
    };
  }, [state.user.token]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 1);
    };

    window.addEventListener('scroll', handleScroll);
    dispatch({
      type: Actions.UpdateAffiliateData,
      payload: {
        cxd: cxd ? (cxd as string | null) : null,
        affid: affid ? (affid as string | null) : null
      }
    });
    if (unsubs && (unsubs as string).length > 0) {
      unsubscribe.mutate(unsubs as string);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (walletConnection) {
      walletConnection
        .start()
        .then(() => {
          console.log('walletConnection Connected!');
          walletConnection.on('UpdateBalance', (balance: unknown) => {
            dispatch({
              type: Actions.UpdateBalance,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              payload: balance
            });
          });
          walletConnection.on('BonusMaxWinNotify', (body: BonusMaxWin) => {
            dispatch({
              type: Actions.ShowPopup,
              payload: {
                state: `${t('account.maxWinReachedMessage')} ${body.value}`
              }
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [walletConnection]);

  useEffect(() => {
    if (challengeConnection) {
      challengeConnection
        .start()
        .then(() => {
          console.log('challengeConnection Connected!');
          challengeConnection.on('ChallengeCompleted', (data: ChallengeGroupCompleted) => {
            dispatch({
              type: Actions.ShowPopup,
              payload: {
                direction: null,
                state: data.message,
                isCongrat: true
              }
            });
            const badgeData = data as ChallengeBadge;
            if (badgeData && badgeData.id > -1) {
              dispatch({
                type: Actions.UpdateUserBadge,
                payload: {
                  challengeLevel: badgeData
                }
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [challengeConnection]);

  const handlePageChange = (_: React.SyntheticEvent, newValue: number) => {
    setPage(newValue);
  };

  const onLanguageItemClick = (lang: string) => {
    i18n.changeLanguage(lang);
    setAnchorElLanguage(null);
    const language = languages.find((l) => l.code.toLowerCase() === lang.toLowerCase());
    setSelectedDbLanguage(language as ILanguageBaseModel);
    setCookie('language', language?.code.toLowerCase() as string, { path: '/', maxAge: 31536000 });
  };

  const getLogo = (additionalStyle?: CSS.Properties) => {
    const logoSrc = isClone ? logoClone : logo;
    return (
      <img
        src={logoSrc}
        alt="logo"
        onClick={() => {
          navigate(PublicRoutes.Home);
          setPage(0);
          setIsMenuOpen(false);
        }}
        style={{ width: 144, height: 30, cursor: 'pointer', ...additionalStyle }}
      />
    );
  };

  const getFlagStyle = (lang: ILanguageBaseModel) => {
    const isSelected = lang.code.toLowerCase() === i18n.language;
    return (
      <Box
        component="img"
        src={lang.flagUrl}
        alt={lang.code}
        className={`flag-img${isSelected ? '--border-blue' : ''}`}
      />
    );
  };
  const getNavigationBackground = () => {
    return isScrolled ? 'rgba(3, 31, 107, 0.57)' : 'transparent';
  };

  return hideNav ? null : (
    <AppBar
      position="fixed"
      sx={{
        height: 80,
        justifyContent: 'center',
        padding: { xs: '0 16px 0 27px', md: '0 40px', background: 'green' },
        boxShadow: isScrolled ? '0 4px 30px rgba(0, 0, 0, 0.1)' : 'none',
        background: getNavigationBackground(),
        backdropFilter: isScrolled ? 'blur(13.5px)' : 'none',
        WebkitBackdropFilter: isScrolled ? 'blur(13.5px)' : 'none'
      }}
    >
      <Toolbar disableGutters>
        <MobileNavigation />
        <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>{getLogo()}</Box>
          <Tabs
            value={page}
            onChange={handlePageChange}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab sx={{ display: 'none' }} />
            {getNavItems().map((n, i) => (
              <Tab
                key={i}
                icon={
                  page === i + 1 ? (
                    <n.iconSelected sx={tabIconStyle} />
                  ) : (
                    <n.icon sx={tabIconStyle} />
                  )
                }
                iconPosition="start"
                label={t(n.text)}
                sx={{
                  ...tabStyle,
                  '&:hover': {
                    color: 'rgb(227,171,0)'
                  }
                }}
                onClick={() => n.onClick(navigate, location)}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', lg: 'inline-block' } }}>
          {isSignedIn ? (
            !hideWallet && (
              <Box style={{ display: 'inline-block' }}>
                <Button
                  onClick={(e) => setAnchorElWallet(e.currentTarget)}
                  className="button__wallet"
                  startIcon={
                    <AccountBalanceWallet sx={{ color: 'common.white', marginRight: '2px' }} />
                  }
                >
                  {state.loadingBalance ? (
                    <PulseLoader color="white" size={10} />
                  ) : (
                    <>
                      {getUserCurrencySymbol(state.user.data?.currency)}
                      {(
                        (state.user.data?.realBalance ?? 0) + (state.user.data?.bonusBalance ?? 0)
                      ).toFixed(2)}
                    </>
                  )}
                </Button>
                <Tooltip title={t('navigation.profileTooltip') as string}>
                  <IconButton
                    onClick={(e) => setAnchorElProfile(e.currentTarget)}
                    sx={{ p: 0, marginRight: '16px' }}
                  >
                    <IconButton
                      sx={{
                        width: '40px',
                        height: '40px',
                        border: '2px solid',
                        color: 'rgb(227,171,0)'
                      }}
                    >
                      <AccountCircle sx={{ color: 'common.white' }} />
                    </IconButton>
                    {Boolean(anchorElProfile) ? (
                      <ArrowDropUp sx={arrowStyle} />
                    ) : (
                      <ArrowDropDown sx={arrowStyle} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )
          ) : (
            <Box style={{ display: 'inline-block' }}>
              <Button className="button__signup" onClick={() => navigate(PublicRoutes.Signup)}>
                {t('account.signUp')}
              </Button>
              <Button className="button__signin" onClick={() => navigate(PublicRoutes.Signin)}>
                <span style={{ color: colors.white }}>{t('account.signIn')}</span>
              </Button>
            </Box>
          )}

          <ProfileMenu
            anchorElProfile={anchorElProfile}
            handleClose={() => setAnchorElProfile(null)}
          />

          <WalletMenu
            anchorElProfile={anchorElWallet}
            handleClose={() => setAnchorElWallet(null)}
          />

          <Tooltip
            title={t('navigation.changeLanguageTooltip') as string}
            className="change-language-tooltip"
          >
            <IconButton onClick={(e) => setAnchorElLanguage(e.currentTarget)}>
              {Boolean(anchorElLanguage) ? (
                <ArrowDropUp className="arrow-drop-icon" />
              ) : (
                <ArrowDropDown className="arrow-drop-icon" />
              )}
              <Box
                component="img"
                src={selecteDbLanguage?.flagUrl}
                alt="Current Language"
                className="flag-img--border-white"
              />
            </IconButton>
          </Tooltip>
          <Menu
            classes={{ paper: 'language-menu' }}
            id="menu-language"
            anchorEl={anchorElLanguage}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            keepMounted
            open={Boolean(anchorElLanguage)}
            onClose={() => setAnchorElLanguage(null)}
          >
            {languages.map((lang) => (
              <MenuItem
                key={lang.id}
                onClick={() => onLanguageItemClick(lang.code.toLowerCase())}
                className="languages-menu-item"
              >
                <Box className="languages-menu-item__display-name">{lang.displayName}</Box>
                {getFlagStyle(lang)}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'inline-block', lg: 'none' } }}>
          {isSignedIn ? (
            <Box>
              <IconButton
                onClick={(e) => setAnchorElWallet(e.currentTarget)}
                sx={{
                  p: 0,
                  width: '40px',
                  height: '40px',
                  border: '2px solid',
                  color: 'rgb(227,171,0)',
                  marginRight: '16px'
                }}
              >
                <AccountBalanceWallet sx={{ color: 'common.white' }} />
              </IconButton>
              <IconButton
                onClick={(e) => setAnchorElProfile(e.currentTarget)}
                sx={{
                  p: 0,
                  width: '40px',
                  height: '40px',
                  border: '2px solid',
                  color: 'rgb(227,171,0)'
                }}
              >
                <AccountCircle sx={{ color: 'common.white' }} />
              </IconButton>
            </Box>
          ) : (
            <Box>
              <IconButton
                onClick={() => navigate(PublicRoutes.Signup)}
                sx={{
                  width: '40px',
                  height: '40px',
                  background: colors.gradients.yellow,
                  marginRight: '16px'
                }}
              >
                <PersonAddAlt1 sx={{ color: 'common.black' }} />
              </IconButton>

              <IconButton
                onClick={() => navigate(PublicRoutes.Signin)}
                sx={{
                  p: 0,
                  width: '40px',
                  height: '40px',
                  border: '2px solid',
                  color: 'rgb(227,171,0)'
                }}
              >
                <Login sx={{ color: 'common.white' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Navigation;
