import React, { forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
//* Models
import { IFreeSpinExchange } from '../../../models/shop';
//* Styles
import './FreeSpinItem.scss';
import { isUserLoggedIn } from '../../../utils/validators';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { PublicRoutes } from '../../../enums/RouteEnums';

interface IProps {
  freeSpin: IFreeSpinExchange;
  prize: null | number | undefined;
  selected: boolean;
  setSelected: (input: IFreeSpinExchange | null) => void;
}

const FreeSpinItem: React.FunctionComponent<IProps> = forwardRef(function FreeSpinItem(props, ref) {
  const { t } = useTranslation();
  // eslint-disable-next-line react/prop-types
  const { selected, setSelected, freeSpin } = props as IProps;
  const { dispatch } = useContext(AppContext);
  // eslint-disable-next-line react/prop-types
  const getAmount = () => freeSpin.amount;

  const handleClick = () => {
    if (!isUserLoggedIn()) {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          state: { path: PublicRoutes.Shop }
        }
      });
    } else {
      setSelected(selected ? null : freeSpin);
    }
  };

  return (
    <Box
      {...props}
      ref={ref}
      className={`free-spins-item ${selected && 'free-spins-item--is-selected'}`}
      onClick={handleClick}
    >
      <Box>{getAmount()}</Box>
    </Box>
  );
});

export default FreeSpinItem;
