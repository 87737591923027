export enum Query {
  AllGames = 'AllGames',
  PassedTournaments = 'PassedTournaments',
  AllTournaments = 'AllTournaments',
  FreeSpins = 'FreeSpins',
  AllChallenges = 'AllChallenges',
  WheelSpin = 'WheelSpin',
  KycToken = 'KycToken',
  Languages = 'Languages',
  AllElements = 'AllElements',
  VerificationStatus = 'VerificationStatus',
  LandingPage = 'LandingPage',
  GetPlayerCards = 'GetPlayerCards',
  Transactions = 'Transactions',
  SuggestedBonuses = 'SuggestedBonuses',
  PlayerActiveBonus = 'PlayerActiveBonus',
  PlayerActiveCashbackBonus = 'PlayerActiveCashbackBonus',
  PastBonuses = 'PastBonuses',
  GetPlayerDetails = 'GetPlayerDetails',
  FooterPage = 'FooterPage',
  GetPlayerSecurityDetails = 'GetPlayerSecurityDetails'
}

export enum Payment {
  Payneteasy = 'Payneteasy',
  Gumball = 'GumballPay',
  Amald = 'Amald',
  SkyChain = 'SkyChain',
  Finateco = 'Finateco'
}
