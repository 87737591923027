import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import './AccountDetailsPage.scss';
import LevelInformation from '../../Navigation/Mobile/LevelInformation/LevelInformation';
import Badge from '../../Challenges/Badge';
import { AppContext } from '../../../AppContext';
import { useQuery } from 'react-query';
import { Query } from '../../../enums/RequestEnums';
import { handleError } from '../../../utils/ui';
import { AllChallengesQuery } from '../../../queries/challenges';
import { CardGiftcardOutlined, SaveAlt, Storefront } from '@mui/icons-material';
import AccountStatus from './AccountStatus';
import AccountUsername from './AccountUsername';
import AccountDetailsHorizontalLine from './AccountDetailsHorizontalLine';
import AccountDetailsBalanceBox from './AccountDetailsBalanceBox';
import { useTranslation } from 'react-i18next';
import AccountDetailsVerifyBox from './AccountDetailsVerifyBox';
import AccountPersonalDetailsBox from './AccountPersonalDetailsBox';
import { GetAccountDetailsQuery } from '../../../queries/profile';
import { AccountDetailsModel } from '../../../models/profile';
import Loader from '../../Common/Loader';
import { Actions } from '../../../enums/ActionEnums';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../enums/RouteEnums';
import { AccountDetailsStatuses } from '../../../models/account';
import { calculateCompletedChallengesPercentage } from '../../../utils/accountDetails';

const AccountDetailsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const getAccountDetails = useQuery({
    queryKey: Query.GetPlayerDetails,
    queryFn: GetAccountDetailsQuery,
    onSuccess: ({ data }) => {
      setUsername((data as AccountDetailsModel).username);
      setVerificationStatus((data as AccountDetailsModel).verificationStatus);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [username, setUsername] = useState(getAccountDetails.data?.data.username || '');
  const [verificationStatus, setVerificationStatus] = useState(
    getAccountDetails.data?.data.verificationStatus || ''
  );

  const challengesQuery = useQuery(Query.AllChallenges, AllChallengesQuery, {
    onSuccess: ({ data }) => {
      setGroupData(data?.groupData);
      setPlaterData(data?.playerData);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [groupData, setGroupData] = useState(challengesQuery.data?.data.groupData || []);
  const [playerData, setPlaterData] = useState(challengesQuery.data?.data.playerData || []);
  const [challengeLevelId, setChallengeLevelId] = useState(
    state.user.data?.challengeLevel?.id ?? 1
  );

  const percentage = calculateCompletedChallengesPercentage(
    groupData,
    challengeLevelId,
    playerData
  );

  return (
    <Box className="details-container">
      <Box className="details-container__top-box">
        <Box className="details-container__status-user-badge-box">
          <Box className="details-container__status-user-box">
            <AccountStatus />
            <AccountUsername username={username} />
          </Box>
          <Box>
            <Badge
              showName
              withBackground
              name={state.user.data?.challengeLevel?.name}
              rank={state.user.data?.challengeLevel?.rank ?? 0}
            />
          </Box>
        </Box>
        <AccountDetailsHorizontalLine />
        <Box className="details-container__level-information-box">
          <LevelInformation
            loyaltyPoints={state.user.data?.loyaltyPoints ?? 0}
            onLinkClick={() => true}
            completedChallengesPercentage={percentage}
            showViewMap={false}
            showOnAccountDetails={true}
          />
        </Box>
      </Box>
      <Box className="details-container__balance-box-container">
        <AccountDetailsBalanceBox
          text={t('wallet.realBalance')}
          value={state.user.data?.realBalance || 0}
          icon={<SaveAlt />}
          onClick={() =>
            dispatch({
              type: Actions.SetMyAccountMenuIndex,
              payload: 1
            })
          }
        />
        <AccountDetailsBalanceBox
          text={t('wallet.bonusBalance')}
          value={state.user.data?.bonusBalance || 0}
          loading={state.loadingBalance}
          icon={<CardGiftcardOutlined />}
          onClick={() =>
            dispatch({
              type: Actions.SetMyAccountMenuIndex,
              payload: 2
            })
          }
        />
        <AccountDetailsBalanceBox
          text={t('shop.titles.pointsBalance')}
          value={state.user.data?.loyaltyPoints ?? 0}
          loading={state.loadingBalance}
          hideCurrency
          showStars
          icon={<Storefront />}
          onClick={() => {
            navigate(PublicRoutes.Shop);
          }}
        />
      </Box>
      {verificationStatus !== AccountDetailsStatuses.Verified &&
        verificationStatus !== AccountDetailsStatuses.Pending && <AccountDetailsVerifyBox />}
      <AccountPersonalDetailsBox />
      <Loader loading={getAccountDetails.isLoading} />
    </Box>
  );
};

export default AccountDetailsPage;
