import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../../AppContext';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import axios from 'axios';
//* MUI
import {
  Box,
  Button,
  Accordion,
  TextField,
  InputAdornment,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
//* MUI Icons
import {
  Done,
  ConfirmationNumber,
  WarningAmberRounded,
  ArrowForwardIosSharp
} from '@mui/icons-material';
//* Components
import Loader from '../../Common/Loader';
import CardForm from '../../Wallet/CardForm';
import InnerLoader from '../../Common/InnerLoader';
import PaymentModal from '../../Wallet/PaymentModal';
// import CustomSwitch from '../../Common/Switch/CustomSwitch';
import DepositAmountBox from './DepositAmountBox/DepositAmountBox';
import DepositPaymentMethod from './DepositPaymentMethod/DepositPaymentMethod';
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import MyAccountRowElement from '../../Common/MyAccountRowElement/MyAccountRowElement';
//* Utils
import { colors } from '../../../utils/theme';
import { getCouponsData, getDepositMethodsData, resizePanNumber } from '../../../utils/profile';
import { getUserCurrencySymbol, handleError } from '../../../utils/ui';
import { IPaymentMethodDto, paymentAmountOptions } from '../../../utils/paymentConstants';
//* Queries
import {
  Deposit,
  DeleteCard,
  PlayerBonuses,
  DepositBalance,
  GetPlayerCards,
  GetPaymentProviders
} from '../../../queries/wallet';
import { GetBonusDetails } from '../../../queries/bonus';
//* Enums
import { Actions } from '../../../enums/ActionEnums';
import { Query, Payment } from '../../../enums/RequestEnums';
import { PublicRoutes } from '../../../enums/RouteEnums';
//* Models
import {
  IDeposit,
  IPlayerCard,
  IDepositResult,
  IUpdateBalance,
  IMyAccountRowElement,
  IPaymentProviderData
} from '../../../models/wallet';
import { IBonus } from '../../../models/bonus';
//* Styles
import './Deposit.scss';

const DepositPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { success, order, order_id, result, customer_order_id, status, Reply, Order } = parsed;
  const { dispatch, state } = useContext(AppContext);
  const [expanded, setExpanded] = useState(0);
  const [cards, setCards] = useState<IPlayerCard[]>([]);
  const [providersData, setProvidersData] = useState<IPaymentProviderData>({
    playerHasDeposited: false,
    providers: []
  });
  const [selectedCard, setSelectedCard] = useState<IPlayerCard | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<IPaymentMethodDto | null>(null);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<IBonus | null>(null);
  const [selectedCouponCode, setSelectedCouponCode] = useState('');
  const [availableBonuses, setAvailableBonuses] = useState<IBonus[]>([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [openCardForm, setOpenCardForm] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [showPaymentProviderOptions, setShowPaymentProviderOptions] = useState(false);
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  const queryClient = useQueryClient();
  const bonusQuery = useMutation(PlayerBonuses, {
    onSuccess: ({ data }) => {
      const bonuses = data as IBonus[];
      const depositBonuses = bonuses.filter((b) => b.type === 'Deposit');
      setAvailableBonuses(depositBonuses);
      const bonusToApply = depositBonuses.find(
        (b) => b.shouldSelect || b.id === location.state?.bonus.id
      );
      setSelectedCoupon(bonusToApply ?? null);
      setSelectedCouponCode(bonusToApply?.code ?? '');
    }
  });

  const playerCardsQuery = useQuery({
    queryKey: [Query.GetPlayerCards],
    queryFn: GetPlayerCards,
    onSuccess: ({ data }) => {
      const playerCards = data as IPlayerCard[];
      setCards(playerCards);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const paymentProvidersQuery = useMutation(GetPaymentProviders, {
    onSuccess: ({ data }) => {
      const providersData = data as IPaymentProviderData;
      setProvidersData(providersData);
      const payneteasyProvider = providersData.providers.find(
        (provider) => provider.name === Payment.Payneteasy
      );
      if (payneteasyProvider) {
        setShowPaymentProviderOptions(false);
        setSelectedPayment(payneteasyProvider);
      } else {
        setShowPaymentProviderOptions(true);
      }
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  useEffect(() => {
    playerCardsQuery.refetch();
    paymentProvidersQuery.mutate();
  }, []);

  const { mutate, isLoading } = useMutation(Deposit, {
    onSuccess: ({ data }) => {
      setOpenCardForm(false);
      const depositResult = data as IDepositResult;
      if (depositResult.redirectUrl && depositResult.redirectUrl.length > 0) {
        setPaymentUrl(data.redirectUrl);
        if (data.shouldRedirect === true) {
          window.location.href = data.redirectUrl;
        } else {
          setOpenPaymentModal(true);
        }
      } else if (depositResult.applied && depositResult.balance) {
        dispatch({
          type: Actions.UpdateBalance,
          payload: depositResult.balance
        });
        dispatch({
          type: Actions.ShowMessage,
          payload: {
            severity: 'success',
            text: t('wallet.depositPage.successfull')
          }
        });
        navigate(PublicRoutes.Games);
      } else {
        dispatch({
          type: Actions.ShowMessage,
          payload: {
            severity: 'error',
            text: t('wallet.depositPage.unsuccessfull')
          }
        });
      }
    },
    onError: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: t('wallet.depositPage.unsuccessfullInvalidBonusCode')
        }
      });
    }
  });

  const balanceUpdate = useMutation(DepositBalance, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateBalance,
        payload: data as IUpdateBalance
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.depositPage.successfull')
        }
      });

      navigate(PublicRoutes.Games);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const bonusDetails = useMutation(GetBonusDetails, {
    onSuccess: ({ data }) => {
      setSelectedCoupon(data);
      setSelectedCouponCode(data.code);
      setExpanded(3);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('deposit');
    }
  });

  const deletePlayerCard = useMutation(DeleteCard, {
    onSettled: () => {
      queryClient.invalidateQueries([Query.GetPlayerCards]);
      dispatch({ type: Actions.HideConfirmation });
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  useEffect(() => {
    bonusQuery.mutate();
    if (order && order.length > 0) {
      setPaymentUrl('');
      setOpenPaymentModal(false);

      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.depositPage.processingYourDepositPayloadMessage')
        }
      });
    } else if (
      (order_id && order_id.length > 0 && result && result === 'success') ||
      (customer_order_id && customer_order_id.length > 0 && status === 'success') ||
      (Reply && Reply === '000' && Order && Order.length > 0)
    ) {
      let message = `${t('wallet.depositPage.processingYourDepositPayloadMessage')}. `;
      if (Reply && Reply === '000' && Order && Order.length > 0) {
        message += `${t('wallet.depositPage.itMayTakeUpFiveMinutes')}.`;
      }
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: message
        }
      });
      //const order = order_id && order_id.length > 0 ? order_id : customer_order_id;
      let order;
      if (order_id && order_id.length > 0) {
        order = order_id;
      } else if (customer_order_id && customer_order_id.length > 0) {
        order = customer_order_id;
      } else {
        order = Order;
      }
      balanceUpdate.mutate(order as string);
    } else if (
      (success && success === 'false') ||
      (result && result === 'failed') ||
      (status && status === 'fail') ||
      (Reply && Reply === '002')
    ) {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'error',
          text: t('wallet.depositPage.depositFailedPayloadMessage')
        }
      });
    }
  }, []);

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const isAmountValid = () =>
    selectedAmount === null ||
    (selectedAmount &&
      selectedAmount > 0 &&
      selectedAmount >= paymentAmountOptions[0] &&
      selectedAmount <= 1000);

  const getDepositTotal = () => (selectedAmount ?? 0) + getBonusAmount();

  const makeDeposit = async () => {
    if (selectedPayment?.name === Payment.Payneteasy) {
      const input = await getPayneteasyData();
      mutate(input);
    } else if (selectedPayment?.name === Payment.SkyChain) {
      const input = getSkyChainData();
      mutate(input);
    } else {
      if (selectedPayment === null) {
        setSelectedPayment(providersData.providers.find((p) => p.isForFtd === false) ?? null);
      }
      setOpenCardForm(true);
    }
  };

  const getPayneteasyData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    const input = {
      amount: selectedAmount as number,
      bonusCode: selectedCouponCode,
      orderId: '',
      payneteasy: Payment.Payneteasy,
      ipAddress: res.data.ip,
      cardNumber: selectedCard?.subtitle
    } as IDeposit;
    return input;
  };

  const makeGumballDeposit = async () => {
    const input = await getGumbleData();
    mutate(input);
  };

  const getAmaldData = async () => {
    const client_orderid = uuidv4();
    const res = await axios.get('https://api.ipify.org/?format=json');

    const input = {
      amount: selectedAmount,
      bonusCode: selectedCouponCode,
      orderId: client_orderid,
      amald: 'Amald',
      ipAddress: res.data.ip,
      cardNumber: cardNumber.trim(),
      cvv,
      cardHolderName,
      expiration: expiration.trim()
    } as IDeposit;
    return input;
  };

  const getGumbleData = async () => {
    const endpointid = process.env.GUMBALL_ENDPOINT_ID;
    const client_orderid = uuidv4();
    const email = state.user.data?.email;
    const merchant_control = process.env.GUMBALL_MERCHANT_CONTROL;
    const controlInput =
      endpointid + client_orderid + (selectedAmount ?? 0) * 100 + email + merchant_control;
    const control = CryptoJS.SHA1(controlInput).toString();
    const res = await axios.get('https://api.ipify.org/?format=json');

    const input = {
      amount: selectedAmount,
      bonusCode: selectedCouponCode,
      orderId: client_orderid,
      gumballHash: control,
      ipAddress: res.data.ip,
      cardNumber,
      cvv,
      cardHolderName,
      expiration: expiration.trim()
    } as IDeposit;
    return input;
  };

  const getSkyChainData = (): IDeposit => {
    const orderId = uuidv4();
    const input = {
      amount: selectedAmount as number,
      bonusCode: selectedCouponCode,
      orderId,
      skyChain: Payment.SkyChain
    } as IDeposit;
    return input;
  };

  const handleCouponCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const coupon = availableBonuses.find((b) => b.code.toUpperCase() === value.toUpperCase());
    setSelectedCoupon(coupon ?? null);
    setSelectedCouponCode(value);
  };

  const getBonusAmount = () => {
    const rations = selectedCoupon?.rationSteps ?? [];
    const sortedRations = rations.sort((a, b) => b.minAmount - a.minAmount);
    let bonusAmount = 0;
    for (let i = 0; i < sortedRations.length; i++) {
      const currentRation = sortedRations[i];
      if (selectedAmount && selectedAmount >= currentRation.minAmount) {
        const multiplier =
          selectedAmount > currentRation.maxAmount ? currentRation.maxAmount : selectedAmount;
        if (selectedCoupon && selectedCoupon.currencies[0]) {
          bonusAmount =
            selectedCoupon.currencies[0].ratioType === 'Percent'
              ? multiplier * (currentRation.ratio / 100)
              : currentRation.ratio;
        }
        break;
      }
    }
    return bonusAmount;
  };

  const handleCouponButtonClicked = () => {
    selectedCouponCode.length > 0 ? bonusDetails.mutate(selectedCouponCode) : setExpanded(4);
  };

  const handleBonusClicked = (bonus: IBonus) => {
    const couponInfo = availableBonuses.filter((availableBonus) => availableBonus.id === bonus.id);
    const coupon = couponInfo[0];

    setSelectedCoupon(selectedCoupon?.code === coupon.code ? null : coupon);
    setSelectedCouponCode(selectedCouponCode === coupon.code ? '' : coupon.code);
    setExpanded(-1);
  };

  const getPaymentMethods = () => {
    const selectedCardId = selectedCard?.id.toString();

    if (providersData.playerHasDeposited && selectedCardId !== 'addNewCard') {
      return providersData.providers.filter((pm) => pm.isForStd === true);
    } else {
      return providersData.providers.filter((pm) => pm.isForFtd === true);
    }
  };

  const submitDeposit = async () => {
    if (selectedPayment?.name === Payment.Gumball) {
      await makeGumballDeposit();
    } else if (selectedPayment?.name === Payment.Amald) {
      const input = await getAmaldData();
      mutate(input);
    } else if (selectedPayment?.name === Payment.Finateco) {
      const res = await axios.get('https://api.ipify.org/?format=json');
      const input = {
        finateco: Payment.Finateco,
        ipAddress: res.data.ip,
        amount: selectedAmount,
        bonusCode: selectedCouponCode,
        cardNumber,
        cvv,
        cardHolderName,
        expiration: expiration.trim()
      } as IDeposit;
      mutate(input);
    }
  };

  const handleCardChange = (card: IPlayerCard) => {
    setSelectedCard(card);

    if (card) {
      const nextSectionIndex = showPaymentProviderOptions ? 1 : 2;
      setExpanded(nextSectionIndex);
    }
  };

  const handlePaymentMethodChange = (payment: IPaymentMethodDto) => {
    setSelectedPayment(payment);

    if (payment) {
      setExpanded(2);
    }
  };

  const handlePaymentAmountChange = (paymentAmount: number) => {
    setSelectedAmount(paymentAmount);
    setExpanded(3);
  };

  const handleDeleteCardConfirmationApprove = (card: IMyAccountRowElement) => {
    deletePlayerCard.mutate(card.id);
  };

  const handleDeleteCardConfirmationOpen = (
    card: IMyAccountRowElement,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const currentCardDetails = cards.filter((currentCard) => currentCard.id === card.id);
    const currentCardNumber = resizePanNumber(currentCardDetails[0].panNumber);
    const confirmation = {
      icon: WarningAmberRounded,
      title: t('wallet.depositPage.cardForm.cardRemoval'),
      agreeAction: () => handleDeleteCardConfirmationApprove(card),
      text: `${t('wallet.depositPage.cardForm.cardRemovalMessage')} ${currentCardNumber}`
    };

    dispatch({ type: Actions.ShowConfirmation, payload: confirmation });
  };

  const paymentMethods = getPaymentMethods();
  const couponsData = getCouponsData(availableBonuses);
  const depositMethodsData = getDepositMethodsData(cards);
  const depositMethodIsDone = selectedCard?.id;
  const selectCouponIsDone = selectedCoupon?.id;
  const amountIsDone = selectedAmount && isAmountValid();
  const addNewCardData = {
    id: 'addNewCard',
    disableExtraControls: true,
    title: t('wallet.depositPage.addNewCard')
  };

  return (
    <Box className="deposit">
      {balanceUpdate.isLoading ? (
        <InnerLoader />
      ) : (
        <>
          <Accordion
            expanded={expanded === 0}
            onChange={handleChange(0)}
            className="deposit__accordion"
          >
            <AccordionSummary
              id={`panel-payment-card-header`}
              aria-controls={`panel-payment-card-content`}
              expandIcon={<ArrowForwardIosSharp className="deposit__accordion-expand-icon" />}
            >
              <Box
                className={`deposit__accordion-title ${
                  depositMethodIsDone ? 'deposit__accordion-title--done' : ''
                }`}
              >
                {depositMethodIsDone && (
                  <Box className="deposit__accordion-title-icon">
                    <Done />
                  </Box>
                )}
                {t('wallet.depositPage.selectDeposit')}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deposit__accordion-content deposit__accordion-content__cards">
                {depositMethodsData.map((depositMethod, index) => (
                  <MyAccountRowElement
                    key={index}
                    element={depositMethod}
                    handleChange={handleCardChange}
                    selectedElementId={selectedCard?.id}
                    handleConfirmation={handleDeleteCardConfirmationOpen}
                  />
                ))}
                <MyAccountRowElement
                  element={addNewCardData}
                  handleChange={handleCardChange}
                  selectedElementId={selectedCard?.id}
                  handleConfirmation={handleDeleteCardConfirmationOpen}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          {showPaymentProviderOptions && (
            <Accordion
              expanded={expanded === 1}
              onChange={handleChange(1)}
              className="deposit__accordion"
            >
              <AccordionSummary
                id={`panel-payment-card-header`}
                expandIcon={<ArrowForwardIosSharp className="deposit__accordion-expand-icon" />}
                aria-controls={`panel-payment-card-content`}
              >
                <Box
                  className={`deposit__accordion-title ${
                    selectedPayment ? 'deposit__accordion-title--done' : ''
                  }`}
                >
                  {selectedPayment && (
                    <Box className="deposit__accordion-title-icon">
                      <Done />
                    </Box>
                  )}
                  {t('wallet.depositPage.selectPaymentProvider')}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box className="deposit__accordion-content deposit__accordion-content__payment-methods">
                  {paymentMethods.map((paymentMethod, index) => (
                    <DepositPaymentMethod
                      key={index}
                      paymentMethod={paymentMethod}
                      selectedPaymentMethod={selectedPayment}
                      handlePaymentMethodChange={handlePaymentMethodChange}
                    />
                  ))}
                  {/* <CustomSwitch
                  checked={saveCardIsChecked}
                  label={t('wallet.depositPage.addNewCard') as string}
                /> */}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            expanded={expanded === 2}
            onChange={handleChange(2)}
            className="deposit__accordion"
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp className="deposit__accordion-expand-icon" />}
              aria-controls={`panel-payment-method-content`}
              id={`panel-payment-method-header`}
            >
              <Box
                className={`deposit__accordion-title ${
                  amountIsDone ? 'deposit__accordion-title--done' : ''
                }`}
              >
                {amountIsDone ? (
                  <Box className="deposit__accordion-title-icon">
                    <Done />
                  </Box>
                ) : null}
                {t('wallet.depositPage.selectAmount')}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deposit__accordion-content deposit__accordion-content__payment-amount">
                <Box className="deposit__accordion-content__payment-amount__options">
                  {paymentAmountOptions.map((paymentAmountOption, index) => (
                    <DepositAmountBox
                      key={index}
                      userCurrency={userCurrency}
                      selectedPaymentAmount={selectedAmount}
                      paymentAmountOption={paymentAmountOption}
                      handlePaymentAmountChange={handlePaymentAmountChange}
                    />
                  ))}
                </Box>
                <Box className="deposit__accordion__input">
                  <TextField
                    fullWidth
                    color="info"
                    type="number"
                    margin="none"
                    id="otherAmount"
                    name="otherAmount"
                    error={!isAmountValid()}
                    value={selectedAmount || ''}
                    placeholder={t('wallet.depositPage.otherAmount') as string}
                    onChange={(e) =>
                      setSelectedAmount(
                        e.currentTarget.value ? Number(e.currentTarget.value) : null
                      )
                    }
                    helperText={
                      <Box
                        className={`deposit__accordion__input-helper-text ${
                          !isAmountValid() ? 'deposit__accordion__input-helper-text--error' : ''
                        }`}
                      >
                        {t('wallet.depositPage.amountHelperText')
                          .toString()
                          .replace(/\€/g, userCurrency)}
                      </Box>
                    }
                    FormHelperTextProps={{
                      sx: { fontSize: '14px', lineHeight: '20px', color: colors.grey.placeholder }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="deposit__accordion__input-adornment"
                        >
                          {userCurrency}
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 3}
            onChange={handleChange(3)}
            className="deposit__accordion"
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharp className="deposit__accordion-expand-icon" />}
              aria-controls={`panel-payment-amount-content`}
              id={`panel-payment-amount-header`}
            >
              <Box
                className={`deposit__accordion-title ${
                  selectCouponIsDone ? 'deposit__accordion-title--done' : ''
                }`}
              >
                {selectCouponIsDone && (
                  <Box className="deposit__accordion-title-icon">
                    <Done />
                  </Box>
                )}
                {t('wallet.depositPage.selectCoupon')}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="deposit__accordion-content deposit__accordion-content__coupons">
                {couponsData.map((bonus, index) => (
                  <MyAccountRowElement
                    key={index}
                    element={bonus}
                    handleChange={handleBonusClicked}
                    selectedElementId={selectedCoupon?.id}
                  />
                ))}
                <Box className="deposit__accordion__input">
                  <TextField
                    fullWidth
                    color="info"
                    margin="none"
                    id="couponCode"
                    name="couponCode"
                    value={selectedCouponCode || ''}
                    onChange={handleCouponCodeChange}
                    placeholder={t('wallet.depositPage.couponPlaceholder') as string}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="deposit__accordion__input-adornment"
                        >
                          <ConfirmationNumber />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <Box className="deposit__accordion__input-end-adornment">
                          <CustomIconButton
                            color="gold"
                            icon={<Done />}
                            onClick={handleCouponButtonClicked}
                          />
                        </Box>
                      )
                    }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box className="checkout-summary">
            <Box className="checkout-summary__title">{t('wallet.depositPage.checkoutSummary')}</Box>
            <Box className="checkout-summary__row">
              <Box>{t('wallet.depositPage.yourDeposit')}:</Box>
              <Box className="checkout-summary__row-info">
                {userCurrency}
                {selectedAmount || 0}
              </Box>
            </Box>
            <Box className="checkout-summary__row">
              <Box className="checkout-summary__row-label">
                {t('wallet.depositPage.yourCoupon')}:{' '}
                {selectedCouponCode.length > 0 && <Box>{`${selectedCouponCode}`}</Box>}
              </Box>
              <Box className="checkout-summary__row-info">
                <Box>
                  {userCurrency}
                  {getBonusAmount()}
                </Box>
              </Box>
            </Box>
            <Box className="checkout-summary__row--large-font checkout-summary__row--with-border">
              <Box className="checkout-summary__row-label">{t('wallet.depositPage.total')}</Box>
              <Box>
                {userCurrency}
                {getDepositTotal()}
              </Box>
            </Box>
            <Box className="checkout-summary__controls">
              <Button
                className="deposit__button"
                variant="contained"
                disabled={
                  selectedPayment === null || selectedAmount === null || selectedAmount < 20
                }
                onClick={() => makeDeposit()}
                sx={{
                  background: colors.gradients.yellow,
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  '&:hover': {
                    background: (t) => t.palette.common.white,
                    boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                  },
                  color: 'primary.main'
                }}
              >
                {t('wallet.deposit')}
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Loader loading={isLoading || bonusDetails.isLoading || bonusQuery.isLoading} />
      {openPaymentModal && <PaymentModal url={paymentUrl} fullscreen={isMobile} />}
      {openCardForm && (
        <CardForm
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          cvv={cvv}
          setCvv={setCvv}
          onClose={() => setOpenCardForm(false)}
          submit={submitDeposit}
          cardHolder={cardHolderName}
          setHolder={setCardHolderName}
          expiration={expiration}
          setExpiration={setExpiration}
          paymentProvider={selectedPayment?.name as string}
        />
      )}
    </Box>
  );
};

export default DepositPage;
