import React from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, InputAdornment, TextField } from '@mui/material';
//* MUI icons
import { CreditCard } from '@mui/icons-material';
//* Components
import AutocompleteField from '../../../../Common/Fields/AutocompleteField';
// import ReceivingDetailsWarningMessage from '../ReceivingDetailsWarningMessage/ReceivingDetailsWarningMessage';
//* Models
import { ICardWithdrawOptionModel } from '../../../../../models/wallet';
//* Enums
//* Utils
import { getCardDetails } from '../../../../../utils/profile';
import { countries, CountryType } from '../../../../../utils/countries';
//* Styles
import './ReceivingDetailsCard.scss';

interface IProps {
  creditCardNumberError: string | null;
  cardDetails: ICardWithdrawOptionModel;
  handleSetCardDetails: (cardDetails: ICardWithdrawOptionModel, cardDetailsKey?: string) => void;
}

const ReceivingDetailsCard: React.FunctionComponent<IProps> = ({
  cardDetails,
  handleSetCardDetails,
  creditCardNumberError
}) => {
  const { t } = useTranslation();

  const getCountriesOptions = () => {
    const countriesOptions = [...countries];

    return countriesOptions;
  };

  const getAutocompleteValue = () => {
    const countryDetails = countriesOptions.find(
      (country) => country.label === cardDetails.cardIssuingCountry
    );

    return countryDetails;
  };

  const renderCardInputAdornment = () => {
    let cardImage = <CreditCard />;

    if (cardDetails.creditCardNumber && cardDetails?.creditCardNumber?.length >= 2) {
      const cardImageDetails = getCardDetails(cardDetails.creditCardNumber);

      if (cardImageDetails.logo.length > 0) {
        cardImage = (
          <Box className="receiving-details__card-info__input-adornment-img">
            <Box component="img" className={cardImageDetails.type} src={cardImageDetails.logo} />
          </Box>
        );
      }
    }

    return cardImage;
  };

  const countriesOptions = getCountriesOptions();
  const autoCompleteValue = getAutocompleteValue();

  return (
    <Box className="receiving-details__bank">
      <Box className="receiving-details__bank-info">
        <AutocompleteField
          id="type"
          isDarkTheme
          inputHeight={56}
          disableClearable
          inputFont="Poppins"
          value={autoCompleteValue}
          options={countriesOptions}
          customClassName="receiving-details__bank-info__dropdown"
          placeholder={t('wallet.withdrawPage.cardIssuingCountry') as string}
          onChange={(event: unknown, newValue: CountryType | null) => {
            if (newValue) {
              handleSetCardDetails({ ...cardDetails, cardIssuingCountry: newValue.label });
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                height: '46px',
                '& > img': { mr: 2, flexShrink: 0 },
                '& .MuiInputBase - input': {
                  height: '46px'
                }
              }}
              {...props}
            >
              <img
                className="receiving-details__bank-filter__option-image"
                width="20"
                loading="lazy"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt="countryFlag"
              />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {option.label}
            </Box>
          )}
          optionLabel={(option) => option.label}
        />
        <TextField
          fullWidth
          color="info"
          margin="none"
          id="otherAmount"
          name="otherAmount"
          value={cardDetails.cardHolderName || ''}
          className="receiving-details__bank-info__input"
          placeholder={t('wallet.withdrawPage.cardHolderName') as string}
          onChange={(event) =>
            handleSetCardDetails({ ...cardDetails, cardHolderName: event.target.value })
          }
        />
        <TextField
          fullWidth
          color="info"
          margin="none"
          type="number"
          id="otherAmount"
          name="otherAmount"
          error={!!creditCardNumberError && creditCardNumberError?.length > 0}
          value={cardDetails.creditCardNumber}
          placeholder={t('wallet.withdrawPage.creditCardNumber') as string}
          className="receiving-details__bank-info__input receiving-details__card-info__input"
          onChange={(event) =>
            handleSetCardDetails(
              { ...cardDetails, creditCardNumber: event.target.value },
              'creditCardNumber'
            )
          }
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className="receiving-details__card-info__input-adornment"
              >
                {renderCardInputAdornment()}
              </InputAdornment>
            )
          }}
          helperText={
            !!creditCardNumberError && creditCardNumberError.length > 0 ? (
              <Box className="withdraw__accordion__input-helper">
                <Box
                  className={`withdraw__accordion__input-helper-text ${
                    creditCardNumberError ? 'withdraw__accordion__input-helper-text--error' : ''
                  }`}
                >
                  {creditCardNumberError}
                </Box>
              </Box>
            ) : null
          }
        />
      </Box>
    </Box>
  );
};

export default ReceivingDetailsCard;
