import React, { useReducer, createContext, Dispatch } from 'react';
import {
  ActionType,
  PopupType,
  MessageType,
  UserType,
  PopupTypeExtended,
  ConfirmationType
} from './models/appcontext';
import { IGameDto } from './models/games';
import { mainReducer } from './reducers/mainReducer';
import { getRoutes, IRouteModel } from './Routes';
import { AffiliateData } from './models/account';

export type InitialStateType = {
  user: UserType;
  message: MessageType;
  loginPopup: PopupType;
  popup: PopupTypeExtended;
  routes: IRouteModel[];
  games: IGameDto[];
  wheelPopup: PopupType;
  loadingBalance: boolean;
  affiliateData: AffiliateData;
  myAccountMenuIndex: number;
  confirmation: ConfirmationType;
};

const InitialState: InitialStateType = {
  user: { data: null, token: null },
  message: { open: false, text: null, severity: null, autoHide: 3000 },
  loginPopup: { open: false, direction: null, state: null },
  popup: { open: false, direction: null, state: null, redirectUrl: null, buttonText: null },
  wheelPopup: { open: false, direction: null, state: null },
  routes: getRoutes(),
  loadingBalance: false,
  affiliateData: { affid: null, cxd: null, landingPage: null },
  games: [],
  myAccountMenuIndex: 0,
  confirmation: { open: false }
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: InitialState,
  dispatch: () => null
});

interface IProps {
  children: React.ReactNode;
}

const AppProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, InitialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
