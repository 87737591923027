import { t } from 'i18next';
import moment from 'moment';
//* Models
import { IActiveBonus, IBonus, IHistoryBonus } from '../models/bonus';
import { IMyAccountRowElement } from '../models/wallet';

export const threeMinutes = 3 * 60 * 1000;

export const borderColor = '1px solid rgba(255, 255, 255, 0.15) !important';

export const bonusItemsPerPage = 7;
export const freeSpinItemsPerPage = 12;

export const getVisibleTableItems = (
  bonuses: JSX.Element[],
  page: number,
  itemsPerPage: number
) => {
  return bonuses.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage);
};

export const getFilteredVisibleBonuses = (
  bonuses: JSX.Element[],
  filter: { id: number; label: string },
  page: number
) => {
  let filteredVisibleBonuses: JSX.Element[] = bonuses;

  if (filteredVisibleBonuses.length > 0 && filter.id === 1) {
    filteredVisibleBonuses = filteredVisibleBonuses.filter((bonus) =>
      bonus.props.element.type.includes('past')
    );
  }

  const visibleBonuses = getVisibleTableItems(filteredVisibleBonuses, page, bonusItemsPerPage);

  return visibleBonuses;
};

export const getFilteredVisibleFreeSpins = (
  page: number,
  filter: string,
  freeSpins: JSX.Element[]
) => {
  let filteredVisibleFreeSpins = freeSpins;

  if (filter.length > 0) {
    filteredVisibleFreeSpins = filteredVisibleFreeSpins.filter((visibleFreeSpin) => {
      if (visibleFreeSpin.key) {
        const filterProp = visibleFreeSpin.key as string;

        return filterProp.toLowerCase().includes(filter.toLowerCase());
      }
    });
  }

  const visibleFreeSpins = getVisibleTableItems(
    filteredVisibleFreeSpins,
    page,
    freeSpinItemsPerPage
  );

  return { visibleFreeSpins, filteredVisibleFreeSpins };
};

export const depositNeededBonusTypes = [
  'Deposit',
  'Registration',
  'Turnover',
  'Verification',
  'Birthday'
];

export const getActiveBonusProgress = (activeBonus: IActiveBonus) => {
  let result = 0;

  if (activeBonus && activeBonus.wagered && activeBonus.toBeWagered) {
    result = Math.round((activeBonus.wagered / activeBonus.toBeWagered) * 100);
  }

  return result;
};

const getBonusExpiresTimestamp = (timestamp: Date | null | undefined) => {
  if (timestamp) {
    const formattedDate = moment(timestamp).format('DD.MM.YYYY');
    const formattedTime = moment(timestamp).format('HH:mm');
    let formattedDateTime = '';

    if (formattedDate.length > 0 && formattedTime.length > 0) {
      formattedDateTime = `${formattedDate} | ${formattedTime}`;
    }

    return formattedDateTime;
  }
};

export const getRecentBonusesData = ({
  language,
  activeBonus,
  suggestedBonuses
}: {
  language: string;
  activeBonus: IActiveBonus | undefined;
  suggestedBonuses: IBonus[];
}) => {
  const recentBonusesArray: IMyAccountRowElement[] = [];
  const recentBonusExpiresTimestamp = getBonusExpiresTimestamp(activeBonus?.expires);

  if (activeBonus) {
    recentBonusesArray.push({
      highlighted: true,
      type: 'activeBonus',
      disableToggle: true,
      id: activeBonus.name,
      title: activeBonus.name,
      disableExtraControls: true,
      subtitle: `${t('myAccount.bonusPage.expires')} ${recentBonusExpiresTimestamp}`
    });
  }

  if (suggestedBonuses.length > 0) {
    suggestedBonuses.map((bonus) => {
      const bonusDescription =
        bonus.promoDetails.find((d) => d.language.toUpperCase() === language.toUpperCase())
          ?.description ?? bonus.promoDetails[0]?.description;

      const element: IMyAccountRowElement = {
        id: bonus.id,
        title: bonus.code,
        disableToggle: true,
        type: 'suggestedBonus',
        subtitle: bonusDescription
      };

      recentBonusesArray.push(element);
    });
  }

  return recentBonusesArray;
};

export const getPastBonusesData = ({ pastBonuses }: { pastBonuses: IHistoryBonus[] }) => {
  const pastBonusesData: IMyAccountRowElement[] = [];

  pastBonuses.map((bonus) => {
    const element: IMyAccountRowElement = {
      id: bonus.id,
      type: 'pastBonus',
      title: bonus.bonus.code,
      // subtitle: 'Subtitle here',
      disableToggle: true,
      disableExtraControls: true
    };

    if (bonus.dateDeactivated !== null) {
      const date = moment(bonus.dateDeactivated).format('DD.MM.YYYY');
      element.subtitle = `${t('myAccount.bonusPage.expired')} ${date}`;
    }

    pastBonusesData.push(element);
  });

  return pastBonusesData;
};

export const getCashBackBonusOptionWithSpaceBetween = (option: string) => {
  if (option && option?.length > 0) {
    if (option === 'RealMoney') {
      return 'Real Money';
    } else if (option === 'ClaimBonus') {
      return 'Bonus Money';
    } else {
      return '';
    }
  } else {
    return '';
  }
};
