import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import i18next, { t } from 'i18next';
import { useQuery } from 'react-query';
//* MUI
import { Box, Button /*, Fade, */ } from '@mui/material';
//* Components
import InfoBox from '../Common/Dialogs/InfoBox';
//* Models
import { LandingPageModel, LandingPageTranslation } from '../../models/landingPage';
//* Enums
import { Query } from '../../enums/RequestEnums';
//* Queries
import { GetLandingPageById } from '../../queries/landingPage';
//* Images
import logo from '../../assets/logo.svg';
//* Styles
import './LandingPage.scss';
import ExternalLogo from '../Common/ExternalLogo';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import Loader from '../Common/Loader';
import { Actions } from '../../enums/ActionEnums';
import useSetMyAccountMenuIndex from '../../customHooks/useSetMyAccountMenuIndex';

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const landingPageExternalId = location.pathname.split('/')[2];
  const [termsAndConditionIsVisible, setTermsAndConditionIsVisible] = useState(false);
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);

  const { data, isLoading, error } = useQuery({
    queryKey: Query.LandingPage,
    queryFn: () => GetLandingPageById(landingPageExternalId)
  });

  useEffect(() => {
    dispatch({
      type: Actions.SetLandingPage,
      payload: {
        landingPage: landingPageExternalId
      }
    });
  }, []);

  const currentLanguage = i18next.language;
  const landingPageData: LandingPageModel = data?.data ?? null;
  const defaultLanguageTranslation = landingPageData?.translations?.find(
    (translation) => translation.language.toUpperCase() === 'EN'
  );
  const currentLanguageTranslation =
    landingPageData?.translations?.find(
      (translation) => translation.language.toUpperCase() === currentLanguage.toUpperCase()
    ) || defaultLanguageTranslation;

  const renderLines = () => {
    if (!isLoading) {
      const lineKeys = Object.keys(currentLanguageTranslation ?? {}).filter((key) =>
        key.includes('line')
      );
      const sortedLineKeys = lineKeys.sort((a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      const lines = sortedLineKeys.map((lineKey) => {
        const key = lineKey as keyof LandingPageTranslation;
        const lineTranslation = currentLanguageTranslation?.[key] as string;
        const lineStyles = landingPageData.lines.find((line) => line.key === key);

        if (lineTranslation?.length > 0) {
          return (
            <Box
              key={lineKey}
              sx={{
                color: lineStyles?.fontColor,
                fontFamily: lineStyles?.font || 'Poppins',
                fontWeight: lineStyles?.fontWeight
              }}
              className={`landing-page-content-section__column-text__line landing-page-content-section__column-text__line--font-size-${lineStyles?.fontSize?.toLowerCase()}`}
            >
              {lineTranslation}
            </Box>
          );
        }
      });

      return lines;
    }
  };

  const renderButton = () => {
    if (!isLoading) {
      return (
        <Box
          className={`landing-page-content-section__column-text__line landing-page-content-section__column-text--align-${landingPageData?.contentAlignment?.toLowerCase()}`}
        >
          <Box
            id="button-container"
            className="landing-page-content-section__column-text__button-container"
          >
            <Button
              onClick={() => handleLandingPageButton()}
              className="landing-page-content-section__column-text__button"
            >
              {currentLanguageTranslation?.buttonText}
            </Button>
          </Box>
        </Box>
      );
    }
  };

  const handleLandingPageButton = () => {
    if (landingPageData.link === PublicRoutes.Wheel) {
      if (state.user.data && state.user.data.hasDeposited) {
        dispatch({ type: Actions.ShowWheelPopup });
      } else {
        if (state.user.data === null) {
          dispatch({
            type: Actions.ShowLoginPopup,
            payload: {}
          });
        } else {
          setMyAccountDepositIndex();
          dispatch({
            type: Actions.ShowPopup,
            payload: {
              state: t('bannersContainer.popUpMessage'),
              isCongrat: false,
              redirectUrl: AuthRoutes.MyAccount,
              buttonText: t('bannersContainer.buttonText') as string
            }
          });
        }
      }
    } else {
      navigate(
        landingPageData.link === PublicRoutes.Signup && state.user.data
          ? AuthRoutes.MyAccount
          : landingPageData.link
      );
    }
  };

  const renderTermsAndConditions = () => {
    if (!isLoading) {
      return (
        <Box
          className={`landing-page-content-section__column-text__line landing-page-content-section__column-text--align-${landingPageData?.contentAlignment?.toLowerCase()}`}
        >
          <button
            className="landing-page-terms-conditions-button"
            onClick={() => {
              setTermsAndConditionIsVisible(true);
            }}
          >
            {t('footer.TermsAndConditions')}
          </button>
        </Box>
      );
    }
  };

  const renderImage = () => {
    if (!isLoading) {
      return (
        <Box
          id="main-img"
          component="img"
          src={landingPageData?.desktopImg}
          className="banner__grid-item-image"
        />
      );
    }
  };

  const renderSteps = () => {
    const orderedSteps = currentLanguageTranslation?.steps?.sort((a, b) => a.order - b.order);

    return orderedSteps?.map((step) => (
      <Box key={step.order} className="landing-page-steps-step">
        <Box className="landing-page-steps-step-order">{step.order}</Box>
        <Box className="landing-page-steps-step-text">{step.text}</Box>
      </Box>
    ));
  };

  const renderFooter = () => {
    const legalAge = <Box className="landing-page-footer-legal-age">18+</Box>;
    const visa = <ExternalLogo name="visa" />;
    const paysafe = <ExternalLogo name="paysafe" />;
    const jcb = <ExternalLogo name="jcb" />;
    const skrill = <ExternalLogo name="skrill" />;
    const imagesArray = [legalAge, visa, paysafe, jcb, skrill];

    return imagesArray.map((image) => image);
  };

  if (error) {
    return navigate(PublicRoutes.LandingPageNotFound);
  }

  return (
    <Box className="landing-page">
      <Box
        className="landing-page-content"
        sx={{ backgroundImage: !isLoading ? `url(${landingPageData?.mobileImg})` : '' }}
      >
        <Box className="landing-page-content-section landing-page-content-section__logo">
          <Box
            src={logo}
            component="img"
            className="landing-page-logo"
            onClick={() => navigate(PublicRoutes.Home)}
          />
        </Box>
        <Box className="landing-page-content-section">
          <Box
            className={`
							landing-page-content-section__column
							landing-page-content-section__column-text
							landing-page-content-section__column-text--align-${landingPageData?.contentAlignment?.toLowerCase()}`}
          >
            {renderLines()}
            {renderButton()}
            {renderTermsAndConditions()}
          </Box>
          <Box className="landing-page-content-section__column">{renderImage()}</Box>
        </Box>
      </Box>
      <Box className="landing-page-steps">{renderSteps()}</Box>
      <Box className="landing-page-footer">{renderFooter()}</Box>
      <InfoBox
        extraHeight
        open={termsAndConditionIsVisible}
        title={t('footer.TermsAndConditions')}
        onClose={() => setTermsAndConditionIsVisible(false)}
      >
        <Box className="landing-page-terms-conditions">
          {currentLanguageTranslation?.termsAndConditions}
        </Box>
      </InfoBox>
      <Loader loading={isLoading} />
    </Box>
  );
};

export default LandingPage;
