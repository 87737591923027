import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './PasswordRequirements.scss';
import { changePassRequirements } from '../../../utils/validators';
import { useTranslation } from 'react-i18next';

interface PasswordRequirementsProps {
  password: string;
  onValidityChange: (allValid: boolean) => void;
  isWhiteTheme?: boolean;
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  onValidityChange,
  isWhiteTheme = false
}) => {
  const { t } = useTranslation();
  const styleSufix = isWhiteTheme ? '--dark' : '';
  const requirements = changePassRequirements(password, t);

  const allValid = requirements.every((req) => req.isValid);

  useEffect(() => {
    onValidityChange(allValid);
  }, [password, allValid, onValidityChange]);

  return (
    <Box className="requirements-container">
      {requirements.map((req, index) => (
        <Box key={index} className="requirements-container__icon-label-box">
          {req.isValid ? (
            <CheckCircleIcon className="check-circle-icon" />
          ) : (
            <CancelIcon className={`cancel-icon${styleSufix}`} />
          )}
          <Box className={`requirements-container__icon-label-box__label${styleSufix}`}>
            {req.label}
          </Box>
        </Box>
      ))}{' '}
    </Box>
  );
};
export default PasswordRequirements;
