import { Actions } from '../enums/ActionEnums';
import { AffiliateData } from '../models/account';
import { ActionType } from '../models/appcontext';

export const affiliateReducer = (state: AffiliateData, action: ActionType): AffiliateData => {
  switch (action.type) {
    case Actions.UpdateAffiliateData:
      const res =
        state.cxd !== null && action.payload.cxd === null ? state : { ...state, ...action.payload };
      return res;
    case Actions.SetLandingPage:
      return { ...state, landingPage: action.payload.landingPage };
    default:
      return state;
  }
};
