import visa1Logo from '../assets/payments/visa1.png';
// import visa2Logo from '../assets/payments/visa2.png';
import visaLogo from '../assets/payments/visa.png';
// import masterCard from '../assets/payments/mastercard.png';
import visaMastercardTransparent from '../assets/payments/visa-mastercard-transparent.svg';
// import paysafeLogo from '../assets/payments/paysafe.svg';
// import jcbLogo from '../assets/payments/jcb.svg';
// import skrillLogo from '../assets/payments/skrill.svg';
import bankTransfer from '../assets/payments/banktransfer_1.svg';
import bitcoin from '../assets/payments/bitcoin.svg';
import { VerificationStatuses } from '../models/account';
import { Payment } from '../enums/RequestEnums';

export interface IPaymentMethodDto {
  img: unknown;
  name: string;
  fee?: number;
  permissions?: VerificationStatuses[];
  isForFtd?: boolean;
  isForStd?: boolean;
  id?: number;
  newImg?: {
    default: string;
    selected: string;
  };
}

export const paymentMethods: IPaymentMethodDto[] = [
  {
    img: visa1Logo,
    name: Payment.Finateco
  },
  {
    img: visaLogo,
    name: Payment.Gumball
  },
  {
    img: visaLogo,
    name: Payment.SkyChain
  }
];

export const withdrawPaymentMethods: IPaymentMethodDto[] = [
  { img: bitcoin, name: 'Bitcoin' },
  //{ img: visaMastercardTransparent, name: 'Card' },
  { img: bankTransfer, name: 'Bank Transfer', permissions: [VerificationStatuses.Verified] }
];

export const paymentAmountOptions = [20, 40, 60, 100];
