import React, { useContext } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import './CashbackScreen.scss';
import cashbackChestImg from '../../../../assets/chashback_chest.png';
import CustomChip from '../../../Common/CustomChip/CustomChip';
import { getUserCurrencySymbol } from '../../../../utils/ui';
import { AppContext } from '../../../../AppContext';
import { IActiveCashbackBonus } from '../../../../models/bonus';
import { getCashBackBonusOptionWithSpaceBetween } from '../../../../utils/bonuses';

type CashbackScreenProps = {
  cashbackBonus: IActiveCashbackBonus;
};

const CashbackScreen: React.FunctionComponent<CashbackScreenProps> = ({ cashbackBonus }) => {
  const { state } = useContext(AppContext);
  const showCollectButton = useMediaQuery('(max-width: 767px)');
  const showAmount = useMediaQuery('(max-width: 575px)');
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  return (
    <Box className="cashback-screen">
      <Box className="cashback-screen__container">
        <Box className="cashback-screen__container__left-side">
          <Box
            component="img"
            src={cashbackChestImg}
            className="cashback-screen__container__left-side__image"
          />
          <Box className="cashback-screen__container__left-side__text">
            <Box className="cashback-screen__container__left-side__text__title">
              {cashbackBonus?.name}
            </Box>
            <Box>
              <CustomChip
                condensed
                label={getCashBackBonusOptionWithSpaceBetween(cashbackBonus?.cashbackPaymentOption)}
                type="highlighted"
              />
            </Box>
          </Box>
        </Box>
        <Box className="cashback-screen__container__right-side">
          <Box className="cashback-screen__container__right-side__cashback-amount">
            + {userCurrency}
            {(cashbackBonus?.amountToReceive).toFixed(2)}
          </Box>
          {/* <Box className="chasback-bonus-collect">
            <Button
              variant="contained"
              onClick={() => {
                true;
              }}
              className="chasback-bonus-collect__button"
            >
              {'Collect'}
            </Button>
          </Box> */}
        </Box>
      </Box>
      {showCollectButton && (
        <Box className="cashback-screen__container__right-side--bottom-mobile">
          {showAmount && (
            <Box className="cashback-screen__container__right-side__cashback-amount--mobile">
              + {userCurrency}
              {cashbackBonus?.amountToReceive}
            </Box>
          )}
          {/* <Button
            variant="contained"
            onClick={() => {
              true;
            }}
            className="chasback-bonus-collect__button--mobile"
          >
            {'Collect'}
          </Button> */}
        </Box>
      )}
    </Box>
  );
};

export default CashbackScreen;
