import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { BonusPromotions, ClaimCashback } from '../../queries/bonus';
import { PromoInfo, Promotion } from '../../models/bonus';
import { handleError } from '../../utils/ui';
import Loader from '../Common/Loader';
import { IPack } from '../../models/pack';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { ActionData } from '../../models/buttons';
//* Components
import PromotionsTile from './PromotionTile/PromotionTile';
import SubNavigation from '../Common/Navigation/SubNavigation';
import InfoBox from '../Common/Dialogs/InfoBox';
import InfoPairs from '../Common/Fields/InfoPairs';
import { getPacksFromPromos, getPromoInfo, getSplittedPromoTitle } from '../../utils/promos';
import { CardGiftcardOutlined, ElectricBolt } from '@mui/icons-material';
//* Styles
import './PromotionsPage.scss';
import useSetMyAccountMenuIndex from '../../customHooks/useSetMyAccountMenuIndex';

const PromotionsPage: React.FunctionComponent = () => {
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);
  const threeMinutes = 3 * 60 * 1000;
  const queryClient = useQueryClient();
  const promotionsQuery = useQuery({
    queryKey: ['Promotions'],
    queryFn: BonusPromotions,
    onSuccess: ({ data }) => {
      const res = data as Promotion[];
      setPromotions(res);
      setPacks(getPacksFromPromos(res));
    },
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });
  const claimCashBack = useMutation(ClaimCashback, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          text: t('promotionsPage.cashbackBonusClaimedMessage') as string,
          severity: 'success'
        }
      });
      navigate(PublicRoutes.Games);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('Promotions');
    }
  });

  const { t, i18n } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [promotions, setPromotions] = useState<Promotion[]>(promotionsQuery.data?.data || []);
  const [page, setPage] = useState(0);
  const [packs, setPacks] = useState<IPack[]>(getPacksFromPromos(promotions));
  const [selectedPack, setSelectedPack] = useState<IPack | null>(null);
  const [promoInfo, setPromoInfo] = useState<PromoInfo | null>(null);
  const [openPromoInfo, setOpenPromoInfo] = useState(false);
  const isAuthed = localStorage.getItem(AppConsts.AccessToken) !== null;

  const handlePackChange = (newPage: number) => {
    setPage(newPage);
    setSelectedPack(newPage === 0 ? null : packs[newPage - 1]);
  };

  const packNames = packs.length > 0 ? ['All', ...packs?.map((pack) => pack.name)] : [];

  const handleClaim = (bonus: Promotion) => {
    setOpenPromoInfo(false);
    if (isAuthed) {
      if (bonus.isCashback) {
        claimCashBack.mutate(bonus.id);
      } else {
        setMyAccountDepositIndex();
        navigate(AuthRoutes.MyAccount, { state: { bonus } });
      }
    } else {
      setMyAccountDepositIndex();
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: { direction: AuthRoutes.MyAccount, state: { bonus } }
      });
    }
  };

  const handleInfoPressed = (promo: Promotion) => {
    const info = getPromoInfo(promo, i18n.language.toUpperCase());
    setPromoInfo(info);
    setOpenPromoInfo(true);
  };

  const closePromoInfo = () => {
    setPromoInfo(null);
    setOpenPromoInfo(false);
  };

  const getInfoActionText = (promoState: string | undefined) => {
    switch (promoState) {
      case 'Claimed':
        return t('promotionsPage.infoActionTextClaimed');
      case 'Locked':
        return promoState;
      default:
        return t('promotionsPage.infoActionTextDefault');
    }
  };

  const getPackIcon = (packName: string) => {
    switch (packName) {
      case 'Welcome':
        return CardGiftcardOutlined;
      case 'Cashback':
        return ElectricBolt;
      default:
        return undefined;
    }
  };

  const renderPromotionTiles = () => {
    let filteredPromotions;

    if (page === 0 || packs.length === 0) {
      filteredPromotions = promotions;
    } else if (page === 2) {
      filteredPromotions = promotions.filter((p) => p.isCashback);
    } else {
      filteredPromotions = promotions.filter((p) => p.packId === selectedPack?.id);
    }

    return filteredPromotions.map((promotion, index) => (
      <PromotionsTile
        key={index}
        index={index}
        promotion={promotion}
        showInfo={handleInfoPressed}
        onClaim={() => handleClaim(promotion)}
      />
    ));
  };

  return (
    <Box className="nav-page">
      <Box className="promotions">
        <Box className="promotions__title">{t('promotionsPage.header')}</Box>
        {packs.length > 0 && (
          <SubNavigation
            selected={page}
            handleChange={handlePackChange}
            options={packNames.map((p) => ({
              label: p,
              icon: getPackIcon(p)
            }))}
          />
        )}
        <Box className="promotions__container">{renderPromotionTiles()}</Box>
      </Box>
      <Loader loading={promotionsQuery.isLoading || claimCashBack.isLoading} />
      <InfoBox
        title={promoInfo?.title ?? ''}
        subtitle={getSplittedPromoTitle(promoInfo?.subtitle)}
        open={openPromoInfo}
        onClose={closePromoInfo}
        actionData={
          {
            text: getInfoActionText(promoInfo?.state),
            onClick: () => handleClaim(promoInfo?.promotion as Promotion),
            state: promoInfo?.state
          } as ActionData
        }
      >
        <InfoPairs items={promoInfo?.items ?? []} />
      </InfoBox>
    </Box>
  );
};

export default PromotionsPage;
