import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Actions } from '../enums/ActionEnums';

const useSetMyAccountMenuIndex = () => {
  const { dispatch } = useContext(AppContext);
  return (menuIndex: number) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: menuIndex
    });
  };
};

export default useSetMyAccountMenuIndex;
