import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AppContext } from '../../../AppContext';
import { AccountDetailsStatuses } from '../../../models/account';
import { GppMaybe, VerifiedUser, GppBad } from '@mui/icons-material';
import './AccountStatus.scss';
import { getUserVerifiactionStatus } from '../../../utils/accountDetails';
import { Query } from '../../../enums/RequestEnums';
import { GetAccountDetailsQuery } from '../../../queries/profile';
import { useQuery } from 'react-query';
import { AccountDetailsModel } from '../../../models/profile';
import { handleError } from '../../../utils/ui';

const AccountStatus: React.FunctionComponent = () => {
  const { dispatch } = useContext(AppContext);
  const getAccountDetails = useQuery({
    queryKey: Query.GetPlayerDetails,
    queryFn: GetAccountDetailsQuery,
    onSuccess: ({ data }) => {
      setUserStatus((data as AccountDetailsModel).verificationStatus);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [userStatus, setUserStatus] = useState(
    getAccountDetails.data?.data.verificationStatus || ''
  );

  const getStatusIcon = () => {
    if (getUserVerifiactionStatus(userStatus) === AccountDetailsStatuses.Verified) {
      return <VerifiedUser sx={{ color: '#F7C903' }} />;
    } else if (
      getUserVerifiactionStatus(userStatus) === AccountDetailsStatuses.Unverified ||
      getUserVerifiactionStatus(userStatus) === AccountDetailsStatuses.Pending
    ) {
      return <GppMaybe />;
    } else if (getUserVerifiactionStatus(userStatus) === AccountDetailsStatuses.Declined) {
      return <GppBad sx={{ color: '#FE033F' }} />;
    }
  };
  return (
    <Box className="container">
      <Box className="container__status-text">{getUserVerifiactionStatus(userStatus)}</Box>
      <Box className="container__icon-box">{getStatusIcon()}</Box>
    </Box>
  );
};

export default AccountStatus;
