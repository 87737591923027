import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { handleError, hideLayout } from '../../utils/ui';
import { colors } from '../../utils/theme';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import FixedButton from './Buttons/FixedButton';
import { ArrowUpward, ChatBubbleOutline, Lock } from '@mui/icons-material';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import homePageBackground from '../../assets/homePage/background2.png';
import wheel from '../../assets/gifs/wheel.gif';
import moneyBox from '../../assets/gifs/moneyBox.gif';
import island from '../../assets/islandBackground.png';
import { GetPlayerWheelSpins } from '../../queries/wheel';
import { useTranslation } from 'react-i18next';
import useSetMyAccountMenuIndex from '../../customHooks/useSetMyAccountMenuIndex';

interface IProps {
  children: React.ReactNode;
}

const LayoutPage: React.FunctionComponent<IProps> = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);
  const hide = hideLayout();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const isClone = process.env.ENVIRONMENT === 'licence';

  const getWheelSpinsQuery = useMutation(GetPlayerWheelSpins, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateUserWheelSpins,
        payload: {
          wheelSpins: data
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetPlayerWheelSpins');
    }
  });

  const getBackgroundProps = (): SxProps => {
    const getUrl = (img: unknown) => `url(${img})})`;

    switch (location.pathname) {
      case PublicRoutes.Challenges:
        return { minHeight: 'auto' };
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    zE('messenger', 'close');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    zE('messenger:on', 'close', function () {
      setChatIsOpen(false);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!!state.user.token) {
      getWheelSpinsQuery.mutate();
    }
  }, [state.user.token]);

  const handleChatPressed = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    zE('messenger', chatIsOpen ? 'close' : 'open');
    setChatIsOpen(!chatIsOpen);
  };

  const handleWheelClick = () => {
    if (state.user.data && state.user.data.hasDeposited) {
      dispatch({ type: Actions.ShowWheelPopup });
    } else {
      if (state.user.data === null) {
        dispatch({
          type: Actions.ShowLoginPopup,
          payload: {}
        });
      } else {
        setMyAccountDepositIndex();
        dispatch({
          type: Actions.ShowPopup,
          payload: {
            state: t('bannersContainer.popUpMessage'),
            isCongrat: false,
            redirectUrl: AuthRoutes.MyAccount,
            buttonText: t('bannersContainer.buttonText') as string
          }
        });
      }
    }
  };

  const getFixedButton = (type: 'chat' | 'wheel' | 'challenge') => {
    if (!isClone) {
      switch (type) {
        case 'chat':
          return (
            <FixedButton position="right" onClick={handleChatPressed}>
              <ChatBubbleOutline />
            </FixedButton>
          );
        case 'challenge':
          return (
            <FixedButton
              position="left"
              onClick={() => navigate(PublicRoutes.Challenges)}
              sx={{
                bottom: '80px',
                backgroundImage: `url(${island})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                overflow: 'hidden',
                width: '60px',
                height: '60px'
              }}
            >
              <Box
                component="img"
                src={moneyBox}
                sx={{ width: '75px', height: '75px', position: 'absolute', top: '-14px' }}
              />
            </FixedButton>
          );
        case 'wheel':
          return (
            <FixedButton
              position="left"
              onClick={handleWheelClick}
              sx={{
                p: 0,
                overflow: state.user.data && state.user.data.hasDeposited ? '' : 'hidden'
              }}
            >
              {state.user.data &&
                state.user.data.wheelSpins > 0 &&
                state.user.data.hasDeposited && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      borderRadius: '100px',
                      backgroundColor: colors.error.main,
                      width: '22px',
                      height: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 3,
                      p: '15',
                      fontSize: '12px',
                      fontWeight: 600
                    }}
                  >
                    {state.user.data.wheelSpins}
                  </Box>
                )}
              {(state.user.data === null || !state.user.data.hasDeposited) && (
                <Box
                  sx={{
                    position: 'relative',
                    width: '100px',
                    height: '100px',
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'gray',
                        opacity: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 100
                      }}
                    />
                    <Lock
                      id="lock-icon"
                      sx={{
                        position: 'relative',
                        color: 'white',
                        opacity: 1,
                        zIndex: 110,
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box
                component="img"
                src={wheel}
                sx={{ width: '45px', height: '45px', position: 'absolute', top: '7px' }}
              />
            </FixedButton>
          );
        default:
          return null;
      }
    }
  };
  return hide ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Box
      sx={{
        backgroundColor: colors.background.blue,
        minHeight: '1080px',
        color: 'common.white',
        width: '100%',
        height: '100%',
        ...getBackgroundProps()
      }}
    >
      {children}
      {getFixedButton('chat')}
      {getFixedButton('wheel')}
      <FixedButton
        position="right"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        sx={{
          bottom: '80px',
          background: 'transparent',
          border: '2px solid white',
          opacity: isScrolled ? 1 : 0
        }}
      >
        <ArrowUpward />
      </FixedButton>
      {getFixedButton('challenge')}
    </Box>
  );
};

export default LayoutPage;
