import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { WarningAmberRounded } from '@mui/icons-material';
//* Components
import Loader from '../../Common/Loader';
import FreeSpins from './FreeSpins/FreeSpins';
import PastBonuses from './PastBonuses/PastBonuses';
import BonusesTable from './BonusesTable/BonusesTable';
import RecentBonuses from './RecentBonuses/RecentBonuses';
//* Utils
import { handleError } from '../../../utils/ui';
import { depositNeededBonusTypes, threeMinutes } from '../../../utils/bonuses';
//* Queries
import {
  ClaimBonus,
  DeclineBonus,
  GetPlayerActiveCashbackBonus,
  PlayerActiveBonus,
  PlayerFreeSpins,
  PlayerPastBonuses,
  PlayerSuggestedBonuses
} from '../../../queries/bonus';
//* Enums
import { Query } from '../../../enums/RequestEnums';
import { Actions } from '../../../enums/ActionEnums';
import { AuthRoutes } from '../../../enums/RouteEnums';
//* Models
import { IPlayerFreeSpins } from '../../../models/shop';
import { IMyAccountRowElement } from '../../../models/wallet';
import {
  IActiveBonus,
  IActiveCashbackBonus,
  IBonus,
  IBonusTableSelectedFilter
} from '../../../models/bonus';
//* Styles
import './MyBonuses.scss';
import FreeSpinsTable from './FreeSpinsTable/FreeSpinsTable';
import useSetMyAccountMenuIndex from '../../../customHooks/useSetMyAccountMenuIndex';
import CashbackScreen from './Cashback/CashbackScreen';

const MyBonuses: React.FunctionComponent = () => {
  const bonusFilterData = [
    { id: 0, label: t('myAccount.bonusPage.showAll') },
    { id: 1, label: t('myAccount.bonusPage.pastTitle') }
  ];
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const setMyAccountIndex = useSetMyAccountMenuIndex();
  const setMyAccountDepositIndex = () => setMyAccountIndex(1);

  const [pastBonuses, setPastBonuses] = useState([]);
  const [activeBonus, setActiveBonus] = useState<IActiveBonus>();
  const [activeCashbackBonus, setActiveCashbackBonus] = useState<IActiveCashbackBonus>();
  const [freeSpins, setFreeSpins] = useState<IPlayerFreeSpins[]>([]);
  const [suggestedBonuses, setSuggestedBonuses] = useState<IBonus[]>([]);
  const [selectedBonusesContent, setSelectedBonusesContent] = useState(0);
  const [addNewCouponIsVisible, setAddNewCouponIsVisible] = useState(false);
  const [enrichedFreeSpins, setEnrichedFreeSpins] = useState<JSX.Element[]>([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState(bonusFilterData[0]);

  const suggestedBonusesQuery = useQuery({
    queryKey: [Query.SuggestedBonuses],
    queryFn: PlayerSuggestedBonuses,
    onSuccess: ({ data }) => {
      const res = data as IBonus[];
      setSuggestedBonuses(res);
    },
    refetchOnMount: 'always',
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const activeBonusQuery = useQuery({
    queryKey: [Query.PlayerActiveBonus],
    queryFn: PlayerActiveBonus,
    onSuccess: ({ data }) => {
      setActiveBonus(data);
    },
    refetchOnMount: 'always',
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const activeCashbackBonusQuery = useQuery({
    queryKey: [Query.PlayerActiveCashbackBonus],
    queryFn: GetPlayerActiveCashbackBonus,
    onSuccess: ({ data }) => {
      setActiveCashbackBonus(data);
    },
    refetchOnMount: 'always',
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const freeSpinsQuery = useMutation(PlayerFreeSpins, {
    onSuccess: ({ data }) => {
      setFreeSpins(data);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('PlayerFreeSpins');
    }
  });

  useEffect(() => {
    freeSpinsQuery.mutate();
  }, []);

  const pastBonusesQuery = useQuery({
    queryKey: [Query.PastBonuses],
    queryFn: PlayerPastBonuses,
    onSuccess: ({ data }) => {
      setPastBonuses(data);
    },
    refetchOnMount: 'always',
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const { mutate, isLoading } = useMutation(ClaimBonus, {
    onSuccess: ({ data }) => {
      const typedData = data as IActiveBonus;
      setSuggestedBonuses(suggestedBonuses.filter((sb) => sb.code !== typedData.name));
      setActiveBonus(typedData);
      dispatch({
        type: Actions.UpdateBonusBalance,
        payload: {
          bonusBalance: typedData.playerBonusBalance as number
        }
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Bonus ${typedData.name} ${t('myAccount.bonusPage.claimed')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ClaimBonus');
    }
  });

  const declineBonusQuery = useMutation(DeclineBonus, {
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('DeclineBonus');
    }
  });

  const handleDeclineBonus = (sb: IMyAccountRowElement) => {
    setSuggestedBonuses(suggestedBonuses.filter((b) => b.id !== sb.id));
    dispatch({ type: Actions.HideConfirmation });
    declineBonusQuery.mutate(sb.title);
  };

  const handleDeleteBonusConfirmationOpen = (bonus: IMyAccountRowElement) => {
    const confirmation = {
      icon: WarningAmberRounded,
      agreeAction: () => handleDeclineBonus(bonus),
      title: t('myAccount.bonusPage.recentBonuses'),
      text: `${t('myAccount.bonusPage.bonusRemovalMessage')} ${bonus.title}?`
    };

    dispatch({ type: Actions.ShowConfirmation, payload: confirmation });
  };

  const handleClaimBonus = (suggestedBonus: IBonus) => {
    if (depositNeededBonusTypes.includes(suggestedBonus.type)) {
      setMyAccountDepositIndex();
      navigate(AuthRoutes.MyAccount, { state: { bonus: suggestedBonus } });
    } else {
      mutate(suggestedBonus.code);
    }
  };

  const handleActivateCoupon = (newCouponCode: string) => {
    mutate(newCouponCode);
    setAddNewCouponIsVisible(false);
  };

  const handleSetAddNewCouponIsVisible = (isVisible: boolean) => {
    setAddNewCouponIsVisible(isVisible);
  };

  const handleSetFreeSpinsData = (enrichedFreeSpinsData: JSX.Element[]) => {
    if (enrichedFreeSpins.length === 0) {
      setEnrichedFreeSpins(enrichedFreeSpinsData);
    }
  };

  const handleBonusTableFilter = (
    selectedBonusContent: number,
    selectedFilterOption: IBonusTableSelectedFilter | null | undefined
  ) => {
    if (selectedFilterOption) {
      setSelectedFilterOption(selectedFilterOption);
    }

    setSelectedBonusesContent(selectedBonusContent);
  };

  const queryIsFetching =
    isLoading ||
    activeBonusQuery.isFetching ||
    activeCashbackBonusQuery.isFetching ||
    pastBonusesQuery.isFetching ||
    suggestedBonusesQuery.isFetching;

  const renderBonusesContent = () => {
    switch (selectedBonusesContent) {
      case 1:
        return (
          <BonusesTable
            activeBonus={activeBonus}
            pastBonuses={pastBonuses}
            handleClaimBonus={handleClaimBonus}
            suggestedBonuses={suggestedBonuses}
            selectedFilterOption={selectedFilterOption}
            handleBonusTableFilter={handleBonusTableFilter}
            handleDeleteBonusConfirmationOpen={handleDeleteBonusConfirmationOpen}
          />
        );
      case 2:
        return (
          <FreeSpinsTable
            freeSpinsData={enrichedFreeSpins}
            setSelectedBonusesContent={setSelectedBonusesContent}
          />
        );
      default:
        return (
          <>
            <Box className="bonuses-box">
              <Box className="bonuses__title">{t('myAccount.bonusPage.recentBonuses')}</Box>
              <RecentBonuses
                activeBonus={activeBonus}
                suggestedBonuses={suggestedBonuses}
                handleClaimBonus={handleClaimBonus}
                handleActivateCoupon={handleActivateCoupon}
                addNewCouponIsVisible={addNewCouponIsVisible}
                handleBonusTableFilter={handleBonusTableFilter}
                handleSetAddNewCouponIsVisible={handleSetAddNewCouponIsVisible}
                handleDeleteBonusConfirmationOpen={handleDeleteBonusConfirmationOpen}
              />
            </Box>
            <Box className="bonuses-box">
              <Box className="bonuses__title">{t('shop.freeSpins.freeSpins')}</Box>
              <FreeSpins
                freeSpins={freeSpins}
                handleSetFreeSpinsData={handleSetFreeSpinsData}
                setSelectedBonusesContent={setSelectedBonusesContent}
              />
            </Box>
            <Box className="bonuses-box">
              <Box className="bonuses__title">{t('myAccount.bonusPage.pastTitle')}</Box>
              <PastBonuses
                pastBonuses={pastBonuses}
                handleBonusTableFilter={handleBonusTableFilter}
              />
            </Box>
            <Loader loading={queryIsFetching} />
          </>
        );
    }
  };

  return (
    <Box className="bonuses">
      {activeCashbackBonus && (
        <CashbackScreen cashbackBonus={activeCashbackBonus as IActiveCashbackBonus} />
      )}
      {renderBonusesContent()}
    </Box>
  );
};

export default MyBonuses;
